import React from 'react'
import ReactPlayer from 'react-player'

function StressRelieveAtHome() {

    const relaxationTips = [
        {
            title: "Breathe In, Breathe Out",
            description: "When stress hits, sometimes all it takes to feel a little lighter is focusing on your breath. Close your eyes, inhale deeply, hold for a moment, then slowly exhale. Try this for a few rounds and notice how your mind starts to calm down. It’s simple but surprisingly powerful.",
            image: "https://picsum.photos/seed/breathe/800/400"
        },
        {
            title: "Create a Cozy Space and Relax",
            description: "Find a cozy spot in your home—maybe a chair by the window or a soft cushion on the floor. Add things that make you feel relaxed: a soft blanket, a little candle, or a scent you love. Take a few minutes there to just be, letting yourself unwind in a space that feels like a mini-retreat.",
            image: "https://picsum.photos/seed/cozyspace/800/400"
        },
        {
            title: "Practice Quick Stretching",
            description: "Stretching or a few yoga poses can do wonders for relieving stress and tension in the body. You don’t need to be a yoga expert to do this. Try basic stretches or simple poses to release back, neck, and shoulder tension.",
            image: "https://picsum.photos/seed/stretching/800/400"
        },
        {
            title: "Listen to Relaxing Music or Nature Sounds",
            description: "Music has the power to influence your mood. Find a calming playlist or tune into some nature sounds, like rain or ocean waves. Close your eyes, take a few deep breaths, and let the sounds transport you to a peaceful state of mind.",
            image: "https://picsum.photos/seed/music/800/400"
        },
        {
            title: "Use the Power of Visualization",
            description: "Visualization is one of the quick stress-relief tools that you can do anywhere. Close your eyes and picture yourself in a peaceful place, like a beach or a deep forest. Imagine the sounds, smells, and feel of the place, letting yourself be fully absorbed in the moment. Just a few minutes of this can help you feel refreshed.",
            image: "https://picsum.photos/seed/visualization/800/400"
        },
        {
            title: "Unplug and Disconnect",
            description: "Constant notifications can add to our stress levels without us even realizing it. Take a short break from your devices. Turn off your phone, step away from your computer, and focus on yourself. Even five minutes can make a big difference.",
            image: "https://picsum.photos/seed/unplug/800/400"
        },
        {
            title: "Take a Few Moments to Practice Gratitude",
            description: "Shift your focus away from stress by reflecting on things you’re grateful for. Focusing on gratitude can instantly lift your spirits and calm your mind.",
            image: "https://picsum.photos/seed/gratitude/800/400"
        }
    ];
    
    return (


        <section className='my-5'>
            <h2 className=' heading-large text-black'>
                How to Relieve Stress Quickly at Home: Best Stress Reduction Skills
            </h2>
            {/* <h2 className='heading-medium text-black'>
                Listen to a Guided Stress Relief Meditation Now
            </h2> */}
            {/* <div className="player-wrapper-bro">
                <ReactPlayer    
                    className="react-player-bro rounded-lg"
                    url={"https://youtu.be/1awmTQ-184I"}
                    width="100%"
                    height={"100%"}
                    controls={true}
                    light={false}
                    playing={false}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 1 }
                        }
                    }}
                />
            </div> */}
            <p className='robotofont mt-4 mb-4'>
            We all experience stress, but you don’t always need a spa day or a long vacation to unwind. There are plenty of quick and effective ways to recharge right at home. Let’s dive into some of the best stress-relief activities that you can easily do in your own space:
            </p>

            <section class="resources-stressed">
                <div class="resources-stressed-grid">

                    {
                        relaxationTips.map((val)=>(
                            <div class="resource-stressed-card">
                            <div class="resource-stressed-card-content">
                                <h3>
                                    {val.title}
                                </h3>
                                <p>
                                    {val.description}
                                </p>
                            </div>
                        </div>
                        ))
                    }
                 
                
                </div>
            </section>

            <p className='robotofont mt-4'>
            By practicing these simple, stress-relief techniques, you’ll find it easier to manage stress. The next time you feel stress creeping in, try a few of these ideas. They’re easy to incorporate into your routine and help you feel calmer, more centered, and ready to take on the day with a fresh perspective.

            </p>

          
        </section>
    )
}

export default StressRelieveAtHome