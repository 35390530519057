import React, { useContext } from 'react';
import "./becomeMember.css";
import iphone from "../../../images/badge_app_store.png"
import android from "../../../images/badge_play_store.png"
import { GiBookCover, GiMeditation } from "react-icons/gi";
import { FaMicroblog } from "react-icons/fa";
import { BiPodcast, BiWorld, BiSupport } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/AllContext';
import MobileBottomImage from '../../../images/home-down-square.webp';
import HomeHeading from './HomeHeading';
import Swal from 'sweetalert2';
import logoimg from "../../../images/newimgs/silvamethod-logo.png";
import { Col, Row } from 'react-bootstrap';
import HeadingNew from '../../AboutUs/HeadingNew';
import HomeHeading22 from './HomeHeading22';

function BecomeMember2({ link = false, linkfunc = () => { }, scrollTo }) {
    const navigate = useNavigate();
    const { isMobile } = useContext(AuthContext);

    const handleGoogleAppBtn = (AppName) => {
        Swal.fire({
            title: 'Stay tuned.',
            text: `Our ${AppName} App is Coming Soon.`,
            imageUrl: logoimg,
            imageWidth: '50%',
            imageHeight: 'auto',
            imageAlt: 'Android image',
        })
    }


    return (
        <section className="memberTodayArea " style={{
            marginTop: isMobile ? "60px" : "120px"
        }}>

            <HomeHeading22 title='Learn ' colored='Silva Method' title2='today' />
            {/* <h5 className="text-center" style={{
                lineHeight: isMobile ? "1.3rem" : "1.5rem",
                fontSize: isMobile ? "0.9rem" : "1rem"
            }}>

            </h5> */}
            <HeadingNew title={"Get access to all courses"} small />

            <div className='container d-flex justify-content-center align-items-center' style={{
                marginTop: isMobile ? "20px" : "50px"
            }}>
                <Row className='justify-content-start align-items-start'>
                    <Col md={6}>
                        <img src={"/img/bg/bghero/bottom-image.webp"} style={{
                            marginBottom: isMobile ? "30px" : "0px"
                        }} />
                    </Col>
                    <Col md={6}>
                        <div className="rightDiv" style={{
                            paddingLeft: isMobile ? "20px" : "0px"
                        }}>
                            <div className="iconArea">
                                <div className="iconImg">
                                    <GiBookCover size={isMobile?30:40} color='#000' />
                                </div>


                                <div className="textArea">
                                    <p className="p1new">Courses</p>

                                    <h5 className="robotofont" style={{
                                        lineHeight: isMobile ? "1.3rem" : "1.5rem",
                                        fontSize: isMobile ? "0.9rem" : "1rem"
                                    }}>A Complete access to all the Silva Method courses developed and designed by Jose Silva.
                                    </h5>
                                </div>
                            </div>
                            <div className="iconArea">
                                <div className="iconImg">
                                    <FaMicroblog size={isMobile?30:40} color='#000' />
                                </div>
                                <div className="textArea">
                                    <p className="p1new">Blogs</p>
                                    <h5 className="robotofont" style={{
                                        lineHeight: isMobile ? "1.3rem" : "1.5rem",
                                        fontSize: isMobile ? "0.9rem" : "1rem"
                                    }}>A Complete access to all the amazing blogs based on daily research.
                                    </h5>
                                </div>
                            </div>
                            <div className="iconArea">
                                <div className="iconImg">
                                    <BiPodcast size={isMobile?30:40} color='#000' />
                                </div>
                                <div className="textArea">
                                    <p className="p1new">Podcasts</p>
                                    <h5 className="robotofont" style={{
                                        lineHeight: isMobile ? "1.3rem" : "1.5rem",
                                        fontSize: isMobile ? "0.9rem" : "1rem"
                                    }}>A Complete access to our amazing audio blogs that get updated at regular intervals.
                                    </h5>
                                </div>
                            </div>
                            <div className="iconArea">
                                <div className="iconImg">
                                    <GiMeditation size={isMobile?30:40} color='#000' />
                                </div>
                                <div className="textArea">
                                    <p className="p1new">Meditation & Healing Sound</p>
                                    <h5 className="robotofont" style={{
                                        lineHeight: isMobile ? "1.3rem" : "1.5rem",
                                        fontSize: isMobile ? "0.9rem" : "1rem"
                                    }}>
                                        A Complete access to the Alpha and Theta sound that will help to achieve the desired brain state easily.
                                    </h5>
                                </div>
                            </div>
                            <div className="iconArea">
                                <div className="iconImg">
                                    <BiWorld size={isMobile?30:40} color='#000' />

                                </div>
                                <div className="textArea">
                                    <p className="p1new">Community</p>
                                    <p className="p1new">Meditation & Healing Sound</p>
                                    <h5 className="robotofont" style={{
                                        lineHeight: isMobile ? "1.3rem" : "1.5rem",
                                        fontSize: isMobile ? "0.9rem" : "1rem"
                                    }}>A Complete access to the amazing Silva community to keep yourself Better & Better.
                                    </h5>
                                </div>
                            </div>
                            <div className="iconArea">
                                <div className="iconImg">
                                    <BiSupport size={isMobile?30:40} color='#000' />
                                </div>
                                <div className="textArea">
                                    <p className="p1new">Support</p>
                                    <h5 className="robotofont" style={{
                                        lineHeight: isMobile ? "1.3rem" : "1.5rem",
                                        fontSize: isMobile ? "0.9rem" : "1rem"
                                    }}>
                                        24/7 support system by the Silva Method Digital team and, if needed, by Mrs. Diana Silva (President of SILVA International and daughter of Mr. Jose Silva)
                                    </h5>
                                </div>
                            </div>
                            <button className="bfs_cta" onClick={() => {
                                if (link) {
                                    linkfunc()

                                } else {
                                    // navigate("/silva-method-courses", { state: { scroll: true } })
                                    scrollTo()

                                }
                            }}>Join now</button>
                            <div className="d-flex justify-content-start align-items-center gap-3 mb-5">
                                <a href="https://apps.apple.com/us/app/silva-method/id6478581512"><img src={iphone} alt="#" loading="lazy" /></a>
                                <a href="https://play.google.com/store/apps/details?id=com.silvamethod"><img src={android} alt="#" loading="lazy" /></a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* <div className="ptop pbtm">
        <div className='w-100'>
            <div className='aboutmobilescreenimagediv'>
              
            </div>
         
        </div>
    </div> */}
        </section>
    )
}

export default BecomeMember2