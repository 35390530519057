import React, { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";

function HomeHeading22({ title = "", colored = "", title2 = "", start = false }) {
  const { isTablet, isMobile } = useContext(AuthContext);

  // Define the gradient animation keyframes
  const gradientAnimation = {
    background: "linear-gradient(337deg, rgba(161,4,3,1) 0%, rgba(196,0,5,1) 49%)",
    backgroundSize: "200% 200%",
    animation: "gradient 5s ease infinite",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  return (
    <>
      <h2
        style={{
          fontWeight: "800",
          fontSize: isMobile || isTablet ? "30px" : "50px",
          textAlign: start ? "start" : "center",
          color:"rgb(15, 19, 27)"
        }}
      >
        {title}{" "}
        <span
          style={{
            ...gradientAnimation,
            fontWeight: "800",
            fontFamily: "Poppins",
            color:"rgb(15, 19, 27)"
          }}
        >
          {colored}
        </span>
        {" "}{title2}
      </h2>
      <style>
        {`
          @keyframes gradient {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
        `}
      </style>
    </>
  );
}

export default HomeHeading22;
