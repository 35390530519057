import React from 'react';
import './MeditationCourseSection.css';
import HeadingNew from '../../AboutUs/HeadingNew';

const NewComboCourseSection = ({scrollTo}) => {
  return (
    <section className="nc-course-section">
    <div className="nc-course-container">
      <div className="nc-course-image nc-animate-slide-in-left">
        <img 
          src="/img/newcourse/2nd-sec-illustration copy-3.webp" 
          alt="Meditation Course"
        />
      </div>

      <div className="nc-course-details nc-animate-slide-in-right">

        <HeadingNew small colored={" Silva Life System and Silva Intuition System"} start />
        

        <ul className="nc-course-features mt-3 ">
          <li>
            <svg viewBox="0 0 20 20" fill="currentColor" style={{
                flex:"none"
            }}>
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
            <span>120+ Lessons, 30+ Chapters, 20+ Exercises</span>
          </li>
          <li>
            <svg viewBox="0 0 20 20" fill="currentColor" style={{
                flex:"none"
            }}>
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
            <span>Lifetime Access to 100% Original Mind Control Courses</span>
          </li>
          <li>
            <svg viewBox="0 0 20 20" fill="currentColor" style={{
                flex:"none"
            }}>
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
            <span>Weekly Live Interactive Sessions led by Instructors</span>
          </li>
        </ul>

        <p className="nc-course-description">
          Unlock a new dimension of personal mastery with the powerful combination of two transformative courses designed to help you take charge of your life and decisions.
        </p>

        <button className="btn-alt-purple" onClick={scrollTo}>
          Enroll Now
        </button>
      </div>
    </div>
  </section>
  );
};

export default NewComboCourseSection;
