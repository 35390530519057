import React from 'react';

const TimeZoneBadge = () => {
  return (
    <div className="tz-container">
      <style>
        {`
          .tz-container {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            justify-content: start;
            margin-top:20px;
            align-items: center;
          }

          .tz-badge {
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.2);
            padding: 0.8rem 1.2rem;
            border-radius: 12px;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            position: relative;
            overflow: hidden;
            animation: badgePulse 2s infinite;
          }

          .tz-badge::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
           
            background-size: 400% 400%;
            z-index: -1;
            border-radius: 13px;
          }

          .tz-zone {
            font-weight: bold;
            font-size: 0.9rem;
            color: #fff;
            text-transform: uppercase;
          }

          .tz-time {
            color: #ffd700;
            font-size: 0.9rem;
            font-weight: 500;
          }

          .tz-dot {
            width: 6px;
            height: 6px;
            background: #ffd700;
            border-radius: 50%;
            margin: 0 0.3rem;
            animation: dotPulse 1.5s infinite;
          }

        

          @keyframes badgePulse {
            0% { box-shadow: 0 0 10px rgba(255, 51, 102, 0.2); }
            50% { box-shadow: 0 0 20px rgba(255, 51, 102, 0.4); }
            100% { box-shadow: 0 0 10px rgba(255, 51, 102, 0.2); }
          }

          @keyframes dotPulse {
            0% { opacity: 0.5; transform: scale(0.8); }
            50% { opacity: 1; transform: scale(1.2); }
            100% { opacity: 0.5; transform: scale(0.8); }
          }

          @media (max-width: 768px) {
            .tz-container {
              gap: 0.8rem;
            }

            .tz-badge {
              padding: 0.6rem 1rem;
            }

            .tz-zone, .tz-time {
              font-size: 0.8rem;
            }
          }

          @media (max-width: 480px) {
            .tz-container {
              align-items: stretch;
            }

            .tz-badge {
              max-width:150px;
              justify-content: space-between;
            }
          }
        `}
      </style>

      {[
        { zone: 'IST', time: '7:00 PM' },
        { zone: 'EST', time: '8:30 AM' },
        { zone: 'PST', time: '5:30 AM' },
        { zone: 'GMT', time: '1:30 PM' }
      ].map((item, index) => (
        <div key={index} className="tz-badge">
          <span className="tz-zone">{item.zone}</span>
          <div className="tz-dot"></div>
          <span className="tz-time">{item.time}</span>
        </div>
      ))}
    </div>
  );
};

export default TimeZoneBadge;