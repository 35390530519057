import React from 'react'
import EmotionalNavbar from '../EmotionalIntelligence/EmotionalNavbar'
import NewFooter2 from '../FreeCourse/NewFooter2'
import HeroSectionEnt from '../EmotionalIntelligence/HeroSectionEnt'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import FeaturedSection from '../SilvaComboCourse/FeaturedSection'
import TrustedBy2 from '../EmotionalIntelligence/TrustedBy2'
import EmotionalWhySection from '../EmotionalIntelligence/EmotionalWhySection'
import Approach from '../hypnosisPage2/ApproachSection'
import ManifestDesires from './ManifestDesires'
import { useMemo } from 'react'
import ManifestDesires2 from './ManifestDesires2'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'
import WhatsAlpha from '../HypnosisPage/WhatsAlpha'
import ComparisonTable2 from '../SilvaComboCourse/ComparisonTable2'
import ComboIncluded from '../SilvaComboCourse/ComboIncluded'
import ComboTestimonials from '../SilvaComboCourse/ComboTestimonials'
import ComboPaymentNew from '../SilvaComboCourse/ComboPaymentNew'
import ContactSection from '../../CoursePage/ContactSection'
import NewComboBanner from '../ComboCourse/NewComboBanner'
import HomePageHeroSection from '../../CoursePage2/HomePageHeroSection'
import { useLocation, useNavigate } from 'react-router-dom'

function Manifestation() {
    const { isMobile } = useContext(AuthContext);
    const location = useLocation()
    const navigation = useNavigate()

    const handleScrollCombo2Curr2 = () => {
        if (location.pathname === "/store/course/combo-plan") {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('token', 'NEWYEAR60');
            window.location.search = urlParams;
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('token', 'NEWYEAR60');
            navigation('/how-to-manifest?token=NEWYEAR60')
            scrollToPayment()

        }
    }

    function scrollToPayment() {
        polyfill()

        const doc = document.querySelector(".herosectionslide")
        setTimeout(() => {

            scrollIntoView(doc, {
                behavior: "smooth",
                block: "end", inline: "nearest"
            });
        }, 300)

    }

    return (
        <div>
            <div style={{
                position: "relative",
                background: "#301934"
            }}>

                <div className='container' style={{
                    position: "absolute",
                    top: "15px",
                    left: "50%",
                    transform: "translateX(-50%)" // Center horizontally
                }}>
                    <EmotionalNavbar />

                </div>
                <div style={{
                    paddingTop:  isMobile ? "120px" : "150px",
                    paddingBottom: isMobile ? "20px" : "120px"
                }}>



                    <HeroSectionEnt />

                </div>
            </div>

            <div className='py-5' style={{
                // background: "#ededed"
            }}>
                <FeaturedSection />

                <TrustedBy2 />


                <div className='pt-3'>
                    <Approach handleScrollCombo2Curr2={handleScrollCombo2Curr2}  />

                    <ManifestDesires />

                    <ManifestDesires2 />

                </div>

            </div>
            <div style={{
                //   background: "#ededed"
            }}>

           

            <WhatsAlpha />




            <div className='d-flex justify-content-center align-items-center my-5'>
                <img src='/img/hypnosis/infographics.png' style={{
                    borderRadius: 10
                }} />
            </div>
            <ComparisonTable2 func={handleScrollCombo2Curr2} />

            <ComboIncluded func={handleScrollCombo2Curr2} />
{/* <NewComboBanner/> */}



            <ComboTestimonials />
            <HomePageHeroSection />




            <ContactSection />
            </div>

            <NewFooter2 />
        </div>
    )
}

export default Manifestation