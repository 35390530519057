import React from 'react'
import { useContext } from 'react'
import { Card } from 'react-bootstrap';
import "./Homestudycourses/productsection.css"
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/AllContext';
import { requestData } from '../../../utils/baseUrl';

function OtherCourses() {

    const navigate = useNavigate()
    const { isDesktopOrLaptop, isBigScreen, userData } = useContext(AuthContext);
    const [listData, setListData] = useState([]);



    const getAllData = async () => {
        const res = await requestData('latestCourseList', "POST", {
            "start_index": "0",
            "no_of_records": "20"
        })



        const mappedData = [...res.data.reverse()].filter((val)=>val.course_id!=="silva-method-complete-course-with-live-interactive-program")
      
      

        const enrolled_course = userData?.enrolled_course;
        const ids = enrolled_course.map((val)=>val.course_id);


        // console.log(mappedData,enrolled_course,"enrolled_course mappedData")


        const cors = mappedData.filter((val,i)=>{
            if(val.is_combo_course==="Yes"){
                const includedcourses = val.included_course;


                const result = includedcourses.every((c)=>ids.includes(c.course_id))
                return !result
            }else{
                return !ids.includes(val.course_id)
            }
        })






        // let resnew = []

        // if(enrolled_course && enrolled_course.length>7 && !ids.includes("unstress-toolbox")){
        //     resnew = mappedData.filter((val)=>val.course_id==="unstress-toolbox")
        // }else if(enrolled_course && enrolled_course.length>7 && enrolled_course.includes("unstress-toolbox")){
        //     resnew = []

        // }else if(enrolled_course && enrolled_course.length<1){
        //     // setListData(mappedData);
        //     resnew = mappedData
        // }
        // else{
        //     const allcourse = mappedData.filter((val)=>enrolled_course.includes(val.course_id))
        //     resnew = allcourse

        // }

        setListData(cors);

    }

    useEffect(() => {
        if (userData) {
            getAllData();
        }
    }, [userData])



    useEffect(()=>{
     console.log(listData.map((val)=>val.web_vertical_image),"listData")
    },[listData])






    if (!listData || (listData && listData.length === 0)) {
        return null
    }
    return (
        <div className='mt-4' style={{ position: "relative" }}>


            <h2 className='mb-3' style={{ fontWeight: "800", fontSize: isDesktopOrLaptop || isBigScreen ? "60px" : "30px", textAlign: "center" }}>
                {
                    userData && userData.enrolled_course.length>0 ? "Other Courses":"Silva Method Courses"
                }
            </h2>
            <h2 className='mb-3' style={{ fontWeight: "800", fontSize: isDesktopOrLaptop || isBigScreen ? "40px" : "25px", textAlign: "center" }}>
                You Might Like
            </h2>

            <div className="container-fluid">
                <div className='productcardsection'>
                    {
                        listData.map((val, i) => (
                            <Card className='productsinglecard' style={{
                                backgroundImage: `url(${val.web_vertical_image || "https://silvamethod.com/manage/assets/uploads/noimg.jpg"})`
                            }}>
                                <Card.Body>
                                    {
                                        (val.discount_percentage && val.discount_percentage != 0) && (
                                            <div className='fourthprice'>

                                            </div>
                                        )
                                    }

                                    {
                                        (!val.discount_percentage || val.discount_percentage == 0) && (
                                            <div className='fourthprice2'>

                                            </div>
                                        )
                                    }
                                    <div className='fourthprices'>


                                        {
                                            (val.discount_percentage && val.discount_percentage != 0) && (
                                                <h3 style={{ textDecoration: "line-through", color: "red" }}>
                                                    <h3 style={{ color: "white" }} className='text-center'>
                                                        ${val.price}
                                                    </h3>
                                                </h3>
                                            )
                                        }

                                        {
                                            (val.discount_percentage && val.discount_percentage != 0) && (
                                                <h4 style={{ color: "white" }} className='text-center'>
                                                    ${Math.floor((val.price) - ((val.discount_percentage * parseInt(val.price)) / 100))}
                                                </h4>
                                            )
                                        }




                                    </div>

                                    <div className="fourthprices2">
                                        {
                                            (!val.discount_percentage || val.discount_percentage == 0) && (
                                                <h4 style={{ color: "white" }} className='text-center'>
                                                    ${val.price}
                                                </h4>
                                            )
                                        }

                                    </div>

                                    <div className='lastfourthcardsection'>

                                        <h4 className='lastfourthcardheader'>
                                            {
                                                val.course_title
                                            }

                                        </h4>

                                        <h5 className='lastfourthcardheader2'>
                                            {val?.total_chapters} {val?.total_chapters < 2 ? "Chapter" : "Chapters"} | {val?.total_lessons} {val?.total_lessons < 2 ? "Lesson" : "Lessons"}
                                        </h5>

                                        <div className='lastfourthsection2'>




                                            {/* <form id="form" action={process.env.REACT_APP_PAYPAL_URL} method="post">
                                                <input type="hidden" name="business" value={process.env.REACT_APP_BUSINESS_EMAIL} />
                                                <input type="hidden" name="cmd" value="_xclick" />
                                                <input type="hidden" name="item_name" value={val.course_title} />
                                                <input type="hidden" name="item_number" value={val.course_id} />
                                                <input type="hidden" name="amount" value={Math.floor((val.price) - ((20 * parseInt(val.price)) / 100))} />
                                                <input type="hidden" name="currency_code" value="USD" />
                                                <input type="hidden" name="custom" value={userData ? userData.student_id : ""} />
                                                <input
                                                    type="hidden"
                                                    name="cancel_return"
                                                    value={`${REACT_BASE_URL}paymentError?courseId=${btoa(val.course_id)}&price=${btoa(Math.floor((val.price) - ((20 * parseInt(val.price)) / 100)))}&coupon=${cp ? btoa(cp) : ""}`}
                                                />
                                                <input
                                                    type="hidden"
                                                    name="return"
                                                    value={`${REACT_BASE_URL}paymentSuccess?courseId=${btoa(val.course_id)}&price=${btoa(Math.floor((val.price) - ((20 * parseInt(val.price)) / 100)))}&coupon=${cp ? btoa(cp) : ""}`}
                                                />
                                                <input
                                                    type="hidden"
                                                    name="notify_url"
                                                    value={`${domainUrl}/paypal-notify`}
                                                />
                                                <input type='submit' className='herodiv-becomememberbtn3' value={"Enroll Now"} />
                                                   
                                            </form> */}

                                            <button className='herodiv-becomememberbtn3' onClick={() => {
                                                navigate(`/store/course/${val.course_id}`)
                                            }}>
                                                Enroll Now
                                            </button>


                                            <span className='fourthsectionspan'>
                                                Life Time Access
                                            </span>

                                        </div>

                                    </div>
                                </Card.Body>
                            </Card>
                        ))
                    }









                </div>
            </div>

        </div>
    )
}

export default OtherCourses