import React, { useEffect } from 'react'
// import Slider1 from './Slider1'
// import Slider2 from './Slider2'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import "./newlandingpage.css"

import HomePageHeroSection from './HomePageHeroSection'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
// import Featured2 from '../LandingPages/EmotionalIntelligence/Featured2'
// import WhatIsSilvaMethodNew from './WhatIsSilvaMethodNew'
// import VideoSectionNew from './VideoSectionNew'
// import LiveBenefitsSection from './LiveBenefitsSection'
// import HomeHeading from '../Home/Home/HomeHeading'
// import HomeEvents from './HomeEvents'
import Featured from '../../components/featured/Featured'
import HeadingNew from '../AboutUs/HeadingNew'
import WhatIsSilvaMethodNew from './WhatIsSilvaMethodNew'
import VideoSectionNew from './VideoSectionNew'
import Slider2 from './Slider2'
import Slider1 from './Slider1'
import LiveBenefitsSection from './LiveBenefitsSection'
import HomeEvents from './HomeEvents'
import ComboImageSection from '../CoursePage/ComboImageSection'
import BreakThroughs from '../silvamembership/BreakThroughs'
import ComboTestimonials from '../LandingPages/SilvaComboCourse/ComboTestimonials'
import AboutSilvaMethod from './AboutSilvaMethod'
import TestimonialSliderCombo from '../LandingPages/ComboCourse2/TestimonialSliderCombo'
import BannerNewHome from './BannerNewHome'
import BecomeMember from '../Home/Home/BecomeMember'
import Newsletter from '../../components/Newsletter/Newsletter'
import MobileSection from './MobileSection'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill';
import { useState } from 'react'
import SaleModal from '../Home/Home/Homestudycourses/SaleModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BannerDownload from './BannerDownload'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import NewHomeHeroSection from './NewHomeHeroSection'
import Homeherodiv from '../Home/Home/HomeHeroSection/Homeherodiv'
import GotoDashboard from './GotoDashboardBanner'
import { BlackFridayTimer } from '../LandingPages/BlackFridaySale/CardAndTimer'
import NewAddOn from './NewAddOn'
import SilvaBanner from './SilvaBanner'


function NewLandingPage() {

  // const [isMouseHovered,setIsMouseHovered] = useState(false);

  const {isMobile} = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const { userData } = useContext(AuthContext)
  const navigation = useNavigate()

  const location = useLocation()

  const token = location?.state?.token;
  const searchParams = new URLSearchParams(location.search);
  const tokenParam = searchParams.get('token');




  const onHide = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  };



  const scrollTo = () => {
    polyfill()
    const doc = document.querySelector(".newpaymentcard");
    setTimeout(() => {

      scrollIntoView(doc, {
        behavior: "smooth",
        block: "end", inline: "nearest"
      });
    }, 300)
  }


  const handleClick = () => {
    if (location.pathname === "/store/course/combo-plan") {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('token', 'NEWYEAR60');
      window.location.search = urlParams;
    } else {
      navigation('/?token=NEWYEAR60')
      scrollTo()

    }
  }



  useEffect(() => {
    let timer;
    if (!token && !tokenParam) {
      if (localStorage.getItem("token") && userData && userData.enrollment_status !== "Enrolled") {
        timer = setTimeout(() => {
          handleShow()

        }, 3000)
      }else if(!localStorage.getItem("token")){

        timer =  setTimeout(() => {
          handleShow()
        }, 3000)

      }

    } else {
      timer =  setTimeout(() => {
        setShow(false)

      }, 3000)

    }

    return ()=>{
      clearInterval(timer)
    }

  }, [token, tokenParam, userData])



  return (
    <>
      <div>

        <HomeHeader />
        {/* <ResponsiveAnimatedNavbar /> */}
        <NewHomeHeroSection scrollTo={scrollTo} />
        {/* <Homeherodiv /> */}
        <div style={{
          background: "whitesmoke",
      
        }}>
          <Featured />

        </div>

        <SilvaBanner />
        <BlackFridayTimer scrollTo={handleClick} />
        <GotoDashboard />



        {/* <BannerPicture /> */}



        <WhatIsSilvaMethodNew scrollTo={scrollTo} />

        
        {/* <img src='/img/bg/Image20241105181002.jpg' className='mt-4' /> */}

        <img src={isMobile?"/img/Image20241112183728.jpg":'/img/bg/Image20241105180621.jpg'} className='mt-4' style={{
          cursor:"pointer",
          borderRadius:isMobile?"15px":"0px"
        }} onClick={()=>{
          handleClick()
        }} />
        {/* <VideoSectionNew /> */}

       
        <Slider1 scrollTo={scrollTo} />
        <Slider2 scrollTo={scrollTo} />
        <LiveBenefitsSection scrollTo={scrollTo} />
     
        <HomePageHeroSection />
        <BannerDownload />
        <HomeEvents scrollTo={scrollTo} />
        <ComboImageSection scrollTo={scrollTo} />
        <BreakThroughs scrollTo={scrollTo} />
        <NewAddOn />
        <ComboTestimonials scrollTo={scrollTo} />
        <AboutSilvaMethod scrollTo={scrollTo} />
        <MobileSection scrollTo={scrollTo} />
        <TestimonialSliderCombo scrollTo={scrollTo} />
        {/* <BannerNewHome scrollTo={scrollTo} /> */}
        <BecomeMember scrollTo={scrollTo} />
        <Newsletter scrollTo={scrollTo} />
        {/* <LiveBenefitsSection />
        <HomeEvents /> */}
      </div>
      {/* <ComboImageSection />
        <BreakThroughs />
        <ComboTestimonials />
        <AboutSilvaMethod />
        <TestimonialSliderCombo />
        <BannerNewHome />

        <BecomeMember />
        <MobileSection />
      <Newsletter /> */}
      <CustomFooter />
      {/* <SaleModal show={show} onHide={onHide} handleShow={handleShow} scrollTo={scrollTo} /> */}
    </>

  )
}

export default NewLandingPage