import { AuthContext } from '../../../context/AllContext';
import { useContext } from 'react';
import ShineBtn from '../SilvaComboCourse/ShineBtn';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import { useState } from 'react';
import Video from "yet-another-react-lightbox/plugins/video";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import { AiOutlineClose } from "react-icons/ai"
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";


const Approach = ({handleScrollCombo2Curr2}) => {
    const [open, setOpen] = useState(false);

    const { isMobile, isTablet, isMediumDesktop } = useContext(AuthContext);
    return (
        <>
            <div className='container'>
                <div className="container">
                    <div className="row align-items-center">
                        {
                            !isMediumDesktop && (
                                <div className="col-lg-6 mb-4 mb-lg-0">
                                    <div style={{
                                        marginBottom: "10px",
                                        padding: isMobile ? "0px" : "25px",
                                        position: "relative"
                                    }}>
                                        <img
                                            className="img-fluid"
                                            src="/img/combo/can you manifest copy.webp"
                                            alt="Image Description"
                                            style={{
                                                width: "95% ",
                                                borderRadius: "20px"
                                            }}
                                        />


                                    </div>


                                </div>
                            )
                        }
                        <div className="col-xl-6">
                            <h2 style={{
                                fontSize: isMobile ? "2rem" : "3rem",
                                color: "black",
                                fontWeight: "700",
                                textAlign: isMobile ? "start" : "start"
                            }}>
                                Manifest Anything you Want
                            </h2>
                            <p style={{
                                fontSize: "1rem",
                                color: "black",
                                margin: "0px",
                                padding: "0px",
                                fontWeight: "400"
                            }} className='my-3'>
                                You have the power to manifest and realize any dream through strong desire, unwavering belief, and positive expectation. Consider manifesting:
                            </p>
                            <div>
                                <StepItem number="1" title="Material aspirations like a new car, a home, or financial abundance." />
                                <StepItem number="2" title="Professional ambitions such as landing your dream job, building a successful career, or starting a thriving business." />
                                <StepItem number="3" title="Health goals include better well-being, recovery from illness or injury, and achieving fitness milestones." />
                                <StepItem number="4" title="Meaningful relationships—be it finding love, nurturing friendships, or enhancing existing connections." />
                                <StepItem number="5" title="Personal development such as boosting self-confidence, finding inner peace, or embarking on a spiritual journey." />
                                <StepItem number="6" title="Overall happiness, fulfillment, and a deep sense of purpose in life." />
                            </div>

                            <p style={{
                                fontSize: "1rem",
                                color: "black",
                                margin: "0px",
                                padding: "0px",
                                fontWeight: "400"
                            }} className='mt-3 mb-3'>
                                Manifestation is about focusing your thoughts and energies towards these aspirations, believing they are achievable, and taking aligned actions towards their realization.

                            </p>
                            {/* <a
                            className="btn rounded-pill mt-3 px-4 py-2"
                            href="#"
                            style={{ color: '#fff', fontWeight: '500', background: "linear-gradient(to right, rgb(127, 0, 255), rgb(225, 0, 255))" }}
                        >

                            Enroll Now
                        </a> */}
                            <ShineBtn onClick={handleScrollCombo2Curr2} text={"Enroll Now"} />
                        </div>
                    </div>
                </div>
            </div>
            <Lightbox

                plugins={[Video, Fullscreen]}
                open={open}
                close={() => setOpen(false)}
                render={{
                    iconClose: () => <AiOutlineClose size={40} color='#fff' />,
                    iconEnterFullscreen: () => <BsArrowsFullscreen size={40} color='#fff' />,
                    iconExitFullscreen: () => <BsFullscreenExit size={40} color='#fff' />,
                }}
                slides={[
                    {
                        type: "video",
                        width: 1280,
                        height: 720,
                        poster: "/img/Image20240617133336.png",
                        autoPlay: true,
                        sources: [
                            {
                                src: "https://res.cloudinary.com/da2cquhhg/video/upload/v1719383259/RAW-1_1_1_zacla2.mp4",
                                type: "video/mp4"
                            }
                        ]
                    },
                    // ...
                ]}
            // ...
            />
        </>
    );
};

const StepItem = ({ number, title }) => {
    const { isMobile } = useContext(AuthContext)
    return (
        <div className="d-flex mb-3 position-relative">
            <div className="flex-shrink-0 me-3">
                <div className="rounded-circle d-flex justify-content-center align-items-center" style={{ width: '2rem', height: '2rem', border: 'none', color: '#fff', fontWeight: '600', fontSize: '0.75rem', textTransform: 'uppercase', background: "linear-gradient(to right, rgb(127, 0, 255), rgb(225, 0, 255))" }}>
                    {number}
                </div>
            </div>
            <div>
                <p style={{
                    fontSize: "1rem",
                    color: "black",
                    margin: "0px",
                    padding: "0px",
                    fontWeight: "500"
                }}>
                    <span style={{ color: 'black' }}>{title}</span>
                </p>
            </div>
            {
                Number(number) !== 6 && (
                    <div className="position-absolute" style={{ top: '2rem', left: '1rem', height: isMobile ? '6rem' : '4rem', width: '2px', backgroundColor: '#8019da' }}></div>
                )
            }
        </div>
    );
};

export default Approach;
