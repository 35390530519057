import React from 'react'
import { Col, Row } from 'react-bootstrap'
import HeadingNew from '../../../AboutUs/HeadingNew'
import { useContext } from 'react'
import { AuthContext } from '../../../../context/AllContext'

function WhatIsMindfulNess() {
  const {isMobile} = useContext(AuthContext);

  
  return (
    <div className={isMobile?'container pt-4 pb-1 px-4':'container py-5'}>
        <Row className='justify-content-center align-items-center'>
            <Col xs={12} md={6} lg={7}>
            <HeadingNew colored={"Mindfulness: What is it?"} start />
            {/* <HeadingNew small title={""} start /> */}
            <p className='robotofont mt-2'>
            Have you ever found yourself doing something without truly being aware of it? Maybe you’ve placed your keys in your bag but keep checking if they’re still there. Or perhaps you’ve read an email twice or thrice, yet nothing seems to stick. If you’re nodding and smiling as you relate to this, then you, my friend, are living on auto-pilot.
            </p>

            <p className='robotofont mt-2'>
            In this introduction to mindfulness, you’ll learn why this automatic way of living might be holding you back. Now, is this really a problem? The answer is a YES! What you just connected with is a clear example of not consciously engaging with your experience in real-time. It can spill over into critical aspects of life—impacting focus at work, relationships, and everyday tasks where attention truly matters. The good news is, there’s a way out of this loop. And that way is through mindfulness. 
            </p>
            <p className='robotofont mt-2'>
         
            Mindfulness is about being fully present in the moment. It’s the gentle practice of training your mind to notice what is happening right now rather than letting your subconscious take over. By focusing on your thoughts without judgement, you open up to the experience as it is, not as you wish it to be. And when you add a touch of mindful self-compassion, you begin to accept yourself, flaws and all, making the practice even more enriching. Incorporating manifestation meditation into your mindfulness routine can further amplify your ability to manifest your desires and align your intentions with your actions.
            </p>
            </Col>
            <Col xs={12} md={6} lg={5}>
             <div className='d-flex justify-content-end align-items-center'>
             <img src='https://res.cloudinary.com/dzkwltgyd/image/upload/v1729752574/glif-run-outputs/caewnhn4dzolz4qsiqdr.jpg' style={{
                width:isMobile?"100%":"90%",
                borderRadius:"15px"
             }} />

             </div>
            </Col>

        </Row>

    </div>
  )
}

export default WhatIsMindfulNess