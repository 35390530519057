import { BsArrowDownCircleFill, BsArrowDownShort } from "react-icons/bs";

const ScrollIndicator = () => {
  return (
    <div className="scroll-indicator-container mt-3">
      <div className="scroll-indicator d-flex flex-column align-items-center">
        <span className="robotofont white-color fw-bold">
        Claim Your Exclusive Bonuses
        </span>
        <BsArrowDownShort color="white" size={50} />
      </div>
      <style jsx>{`
        .scroll-indicator-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .scroll-indicator {
          animation: bounce 1.5s infinite;
          cursor: pointer;
          color: #666;
        }

        @keyframes bounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(10px);
          }
        }

        .scroll-indicator:hover {
          color: #333;
        }
      `}</style>
    </div>
  );
};

export default ScrollIndicator;