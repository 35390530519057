import React from 'react'
import HomeHeader from '../../../Home/Home/HomeHeader/HomeHeader'
import Herosectionblog2 from './Herosectionblog2'
import SleepMeditationSection from './SleepMeditation'
import "./blogs2.css"
import CausesOfInsomniaSection from './CausesOfInsomnia'
import SleepDeprivationSection from './SleepDerived'
import BedTime from './BedTime'
import NightMeditationBenefits from '../BlogLandingPage1/NightMeditationBenefits'
import MeditationTechniques from './MeditationTechniques'
import SilvaMethodWithStyles from './ExploreSection'
import BlogFaqs from './BlogFaqs'
import CustomFooter from '../../../Home/Home/HomeFooter/Footer'
import FormSection from '../../FreeCourse/FormSection'
function BlogLandingPage2() {
  return (
    <>
      <HomeHeader />
      <Herosectionblog2 />
      <SleepMeditationSection />
      <div className='newsec'>
        <FormSection />
      </div>
      <CausesOfInsomniaSection />
      <SleepDeprivationSection />
      <BedTime />
      <NightMeditationBenefits />
      <MeditationTechniques />
      <SilvaMethodWithStyles />
      <BlogFaqs />
      <CustomFooter />


    </>
  )
}

export default BlogLandingPage2