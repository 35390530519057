import React, { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';
import Slider from 'react-slick';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { GlowingBadge } from './BlackFridayHero2';
import useCountdown from './CountDown';

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="slider-arrow slider-arrow-next"
  >
    <BiChevronRight size={24} color='white' />
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="slider-arrow slider-arrow-prev"
  >
    <BiChevronLeft size={24} color='white' />
  </button>
);

export const ProductGrid = ({ scrollTo }) => {
  const { isMobile } = useContext(AuthContext);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const products = [
    { id: 1, name: 'Silva Life System', price: '$159', image: 'https://silvamethod.com/manage/assets/uploads/silva-lifesystem_1.jpg', link: "/store/course/silva-life-system-digital-audio" },
    { id: 2, name: 'Silva Intuition System', price: '$159', image: 'https://silvamethod.com/manage/assets/uploads/silva-intuition.jpg', link: "/store/course/silva-intuition-system-digital-audio-program" },
    { id: 3, name: 'Silva Manifesting System', price: '$159', image: 'https://silvamethod.com/manage/assets/uploads/silva-manifest_1.jpg', link: "/store/course/silva-method-manifesting-program" },
    { id: 4, name: 'Silva Mind Body Healing', price: '$159', image: 'https://silvamethod.com/manage/assets/uploads/silva-mind-body.jpg', link: "/store/course/silva-mind-body-healing-program" },
    { id: 5, name: "Alpha Sound", price: "$40", image: "https://silvamethod.com/manage/assets/uploads/silva-alpha-sound.jpg", link: "/store/course/silva-alpha-sound" },
    { id: 6, name: "Theta Sound", price: "$40", image: "https://silvamethod.com/manage/assets/uploads/theta-sound.jpg", link: "/store/course/silva-theta-sound" }
  ];

  return (
    <div className="container slider-container">
      <HeadingNew title={"Inside the Silva Method Complete Program"} />
      
      <div className="slider-wrapper">
        <Slider {...settings}>
          {products.map((product) => (
            <div key={product.id} className="slider-item">
              <div 
                className="dsc_item_j2"
                style={{ cursor: "pointer" }}
                onClick={scrollTo}
              >
                <div className="product-image-container">
                  <img 
                    src={product.image} 
                    alt={product.name} 
                    className="dsc_img_p5" 
                  />
                </div>
                <h3 className="dsc_name_r8 white-color robotofont">{product.name}</h3>
                <p className="dsc_price_l3 white-color robotofont">{product.price}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};


// BlackFridayTimer.jsx

export const BlackFridayTimer = ({ scrollTo }) => {
  const { days, hours, minutes, seconds, isActive } = useCountdown("2025-01-01")

  if (!isActive) {
    return null
  }
  return (
    <div className="bf_container_y6 py-5">
        {/* <p className='bf_subtitle_q9 robotofont' style={{
          fontSize:"1.5rem"
        }}>
      <span style={{
          fontWeight:"800"
        }}>The Black Friday </span>  Sale is Extended by <span style={{
          fontWeight:"800"
        }}>Popular Demand </span>
        </p>
        <p className='bf_subtitle_q9 robotofont mb-4' style={{
          fontSize:"1.5rem"
        }}>
        Grab It Before It’s Gone!
        </p> */}
      <GlowingBadge />

           
      <h2 className="bf_title_w2 mt-3">Flat 60% OFF</h2>

      <h2 className="bf_special_h4">On the Silva Method Complete Course</h2>
      <h2 className="bf_subtitle_q9 mt-3">Transform Your New Year Now!</h2>
      <div className="bf_timer_v8">
        <div className="bf_box_u3">
          <span className="bf_digits_t7">{days}</span>
          <span className="bf_label_s1">DAYS</span>
        </div>
        <div className="bf_box_u3">
          <span className="bf_digits_t7">{hours}</span>
          <span className="bf_label_s1">HOURS</span>
        </div>
        <div className="bf_box_u3">
          <span className="bf_digits_t7">{minutes}</span>
          <span className="bf_label_s1">MINUTES</span>
        </div>
        <div className="bf_box_u3">
          <span className="bf_digits_t7">{seconds}</span>
          <span className="bf_label_s1">SECONDS</span>
        </div>

      </div>
      <button className='bfs_cta mt-4' onClick={scrollTo}>
        Enroll Now

      </button>
    </div>
  );
};

/* styles.css */
