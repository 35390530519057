import React from 'react';

const StressImportant = () => {
  const styles = `
    .management-container {
      font-family: system-ui, -apple-system, sans-serif;
      background: linear-gradient(135deg, #f5f3ff 0%, #ffffff 100%);
    }

    .hero-image {
      position: relative;
      height: 500px;
      background: linear-gradient(rgba(76, 29, 149, 0.7), rgba(124, 58, 237, 1));
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .hero-text {
      text-align: center;
      color: white;
      max-width: 800px;
      padding: 0 20px;
      position: relative;
    }

    .hero-title {
      font-size: 3.5rem;
      font-weight: 800;
      margin-bottom: 20px;
      line-height: 1.2;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }

    .content-section {
      max-width: 1200px;
      margin: -100px auto 60px;
      padding: 0 20px;
      position: relative;
      z-index: 2;
    }

    .content-grid {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 40px;
      align-items: start;
    }

    .main-content {
      background: white;
      padding: 60px;
      border-radius: 20px;
      box-shadow: 0 20px 40px rgba(124, 58, 237, 0.1);
    }

    .management-text {
      font-size: 1.125rem;
      line-height: 1.8;
      color: #4b5563;
      margin-bottom: 30px;
    }

    .side-image {
      position: sticky;
      top: 40px;
    }

    .side-image img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      border-radius: 20px;
      box-shadow: 0 20px 40px rgba(124, 58, 237, 0.1);
    }

    .highlight-text {
      font-size: 1.4rem;
      line-height: 1.6;
      color: #4c1d95;
      font-weight: 600;
      padding: 30px;
      background: linear-gradient(135deg, #f5f3ff 0%, #faf5ff 100%);
      border-radius: 12px;
      margin-top: 40px;
    }

    @media (max-width: 1024px) {
      .content-grid {
        grid-template-columns: 1fr;
      }

      .side-image {
        display: none;
      }
    }

    @media (max-width: 768px) {
      .hero-image {
        height: 400px;
      }

      .hero-title {
        font-size: 2.5rem;
      }

      .content-section {
        margin-top: -60px;
      }

      .main-content {
        padding: 40px;
      }
    }

    @media (max-width: 480px) {
      .hero-image {
        height: 300px;
      }

      .hero-title {
        font-size: 2rem;
      }

      .content-section {
        margin-top: -40px;
        padding: 0 15px;
      }

      .main-content {
        padding: 30px;
      }

      .management-text {
        font-size: 1rem;
      }

      .highlight-text {
        font-size: 1.2rem;
        padding: 20px;
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="management-container my-5">
        <div className="hero-image">
          <div className="hero-text">
            <h1 className="hero-title white-color">Why is Stress Management Important?</h1>
          </div>
        </div>

        <div className="content-section">
          <div className="content-grid">
            <div className="main-content">
              <p className="management-text">
                Stress management is crucial for maintaining both mental and physical well-being. When left unchecked, stress can lead to serious health issues, such as anxiety and depression, and it can even destroy your personal and professional relationships. Learning ways to manage stress can genuinely feel like discovering a hidden superpower.
              </p>
              <p className="management-text">
                You can face daily challenges with calm confidence rather than feeling overwhelmed. By embracing techniques like mindfulness, physical activity, and deep breathing, you can equip yourself to tackle life's ups and downs more effectively. These stress-coping strategies not only help you regain control but also enhance your focus and creativity.
              </p>
              <p className="management-text">
                When you prioritize stress management techniques, you reduce anxiety and pave the way for a more joyful, balanced existence.
              </p>
              <div className="highlight-text">
                So, take a moment for yourself today—a little self-care can go a long way in transforming stress into strength!
              </div>
            </div>
            <div className="side-image">
              <img src="/img/stress/stress%20reduction%20-banner.webp" alt="Peaceful scene" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StressImportant;