import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import HeadingNew from '../../../AboutUs/HeadingNew'
import { FaRegLightbulb } from 'react-icons/fa'
import { useContext } from 'react'
import { AuthContext } from '../../../../context/AllContext'
import ReactPlayer from 'react-player'

function Herosectionblog2() {
    const { isMobile } = useContext(AuthContext);

    return (
        <>
            <div className='container d-flex justify-content-center align-items-center flex-column gap-3' >
                {/* <h3 style={{
                    color: "black",
                    fontSize: isMobile ? "1.5rem" : "2rem",
                    textAlign: "center"
                }}
                    className='lora-sans'
                >
                    Listen to a Guided Deep Sleep Meditation Now
                </h3>
                <Card className='w-100 rounded'>

                    <div className="player-wrapper-bro">
                        <ReactPlayer
                            className="react-player-bro"
                            url={"https://youtu.be/1awmTQ-184I"}
                            width="100%"
                            height="100%"
                            controls={true}
                            light={false}
                            playing={false}
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 1 }
                                }
                            }}
                        />
                    </div>

                  
                </Card> */}

            </div>
            <div className='hero-background'>

                <div className='container py-5'>
                    <Row className='px-4 justify-content-center align-items-center' style={{
                        height: isMobile ? "auto" : "60vh"
                    }}>
                        <Col md={6}>
                            <h3 style={{
                                color: "white",
                                fontSize: isMobile ? "2rem" : "3rem"
                            }}
                                className='lora-sans text-start '
                            >
                                <span style={{ color: '#FFEA00' }}>
                                    Sleep  Meditation
                                </span>
                            </h3>

                            <h3 style={{
                                color: "white",
                                fontSize: isMobile ? "1.5rem" : "2rem"
                            }}
                                className='lora-sans text-start '
                            >
                                The Ultimate Guide for a Restful Night
                            </h3>
                            {
                                isMobile && (
                                    <div className='d-flex justify-content-end align-items-end my-3 ' >
                                        <div className="player-wrapper-bro">
                                            <ReactPlayer
                                                className="react-player-bro"
                                                url={"https://youtu.be/1awmTQ-184I"}
                                                width="100%"
                                                height="100%"
                                                controls={true}
                                                light={false}
                                                playing={false}
                                                config={{
                                                    youtube: {
                                                        playerVars: { showinfo: 1 }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div style={{
                                backgroundColor: 'transparent',  // Light background color for contrast
                                border: '1px solid #dfe6e9',  // Subtle border for separation
                                padding: '20px',              // Padding inside the box
                                borderRadius: "15px",
                                border: "1px solid white",
                                // Rounded corners for smooth look
                                marginTop: '20px',            // Space between the heading and this section
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: "wrap",
                                gap: 10
                            }}>

                                <div className='d-flex justify-content-center align-items-start gap-3 '>
                                    <span>  <FaRegLightbulb style={{
                                        fontSize: '1.5rem',           // Icon size
                                        color: '#fff',           // Icon color
                                        marginRight: '5px'         // Space between icon and text
                                    }} /></span>

                                    <p className='robotofont white-color' style={{
                                        fontSize: '1.1rem',         // Text size
                                        lineHeight: '1.6',          // Line height for readability
                                        fontWeight: '400',          // Text weight
                                        margin: 0                   // Remove margin around the paragraph
                                    }}>
                                        Did you know that about one in three adults struggle with insomnia and around 10% are actually diagnosed with insomnia disorder?
                                    </p>

                                </div>

                                {/* <p className='robotofont' style={{
                                fontSize: '1.1rem',         // Text size
                                lineHeight: '1.6',          // Line height for readability
                                fontWeight: '400',          // Text weight
                                color: '#555',              // Text color
                                margin: 0                   // Remove margin around the paragraph
                            }}>
                                Did you know that about one in three adults struggle with insomnia and around 10% are actually diagnosed with insomnia disorder?
                            </p> */}
                            </div>

                            <p className='robotofont mt-3 white-color' style={{
                                fontSize: "1.1rem"
                            }}>
                                In our busy, always-on world, proper sleep of 7 - 9 hours is like a blessing - that many struggle to attain. If you’re one of those who lie awake at night, frustrated by the constant tossing and turning, sleep meditation might be the key to finally getting the rest you need. It’s a simple but powerful way to calm your mind and body, setting the stage for a deep, peaceful sleep.


                            </p>
                        </Col>
                        {
                            !isMobile && (
                                <Col md={6}>
                                    <div className="player-wrapper-bro mx-4">
                                        <ReactPlayer
                                            className="react-player-bro"
                                            url={"https://youtu.be/1awmTQ-184I"}
                                            width="100%"
                                            height="100%"
                                            controls={true}
                                            light={false}
                                            playing={false}
                                            config={{
                                                youtube: {
                                                    playerVars: { showinfo: 1 }
                                                }
                                            }}
                                        />
                                    </div>
                                </Col>
                            )
                        }

                    </Row>

                </div>
            </div>
        </>

    )
}

export default Herosectionblog2