import React from 'react'
import {GrFormPrevious,GrFormNext} from "react-icons/gr";
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';


function AfterOrderSectionNewCombo() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
    const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1279 })
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 })


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isDesktopOrLaptop?3:isTablet?2:1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
    };
  return (
    <div className='my-5 py-5' style={{backgroundColor: "rgb(155, 55, 242)"}}>
         <h2 className='text-center white-color mb-5'>
         Explore What the Course Includes

         </h2>
         
       
       <div className="container orderslider">
       <Slider {...settings}>
         <div className='d-flex flex-column justify-content-end align-items-center'>
            <img src={"/img/newcourse/desktop.webp"}
            alt=""
            />
            <p style={{fontSize:isMobile?"1rem":"1.1rem",color:"black",width:isMobile?"100%":"330px",wordBreak:"break-word"}} className='white-color'>
            Master life’s chaos effortlessly with the Silva Life System

            </p>

         </div>
         <div className='d-flex flex-column justify-content-end align-items-center'>
            <img src={"/img/newcourse/laptop.webp"}
            alt=""
            />
            <p style={{fontSize:isMobile?"1rem":"1.1rem",color:"black",width:isMobile?"100%":"330px",wordBreak:"break-word"}} className='white-color'>
            Awaken your Intuitive Mind with Silva Intuition System

            </p>

         </div>
         <div className='d-flex flex-column justify-content-end align-items-center'>
            <img src={"/img/newcourse/tab-and-mobile copy-3.webp"}
            alt=""
            />
            <p style={{fontSize:isMobile?"1rem":"1.1rem",color:"black",width:isMobile?"100%":"330px",wordWrap:"break-word"}} className='white-color'>
            Join Weekly Doubt Clearing Sessions Live with Silva Method

            </p>

         </div>
         </Slider>
       </div>
    </div>
  )
}

export default AfterOrderSectionNewCombo