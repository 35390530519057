import React from 'react';
import HeadingNew from '../../AboutUs/HeadingNew';
import SingleChapterAccordion from '../../SingleCoursePage/SingleChapterAccordion';
import NewPaymentCard from '../../SingleCoursePage/NewPaymentCard';
import NewPaymentCard3 from '../../SingleCoursePage/NewPaymentCard2';

const CustomImageGalleryWithSidebar3 = ({courseData,isCurrentCoursePurchased,allCourseData}) => {
  return (
    <main className='py-4'>
      <header></header>
      <div>
        {/* Nested on purpose to test it */}
        <div className="custom-container">
          <section className="custom-images">
           
            <div className='mb-3'>
              <HeadingNew title={"Course Details"} start={true} />
            </div>
            {
                allCourseData && allCourseData.length>0 && allCourseData.map((val,i)=>(
                    <div className='py-3'>
                    <div className='mb-2'>
                    <HeadingNew start small title={val[0].course_title} />
                    </div>
                    <SingleChapterAccordion courseData={val[0]} isCurrentCoursePurchased={isCurrentCoursePurchased} />
                    </div>
                ))
            }
          </section>
        
              <aside className="custom-sidebar">
           
              <NewPaymentCard3 isCurrentCoursePurchased={isCurrentCoursePurchased} />
            </aside>
            
        </div>
      </div>
    </main>
  );
};

export default CustomImageGalleryWithSidebar3;
