import React, { useContext, useState } from 'react';
import './thankyou.css';
import { Card, Col, Row } from 'react-bootstrap';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import { BsArrowsFullscreen, BsFullscreenExit } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import HeadingNew from '../AboutUs/HeadingNew';
import { AuthContext } from '../../context/AllContext';


function CardsThankyou() {
  const {isMobile} = useContext(AuthContext)
  const [open, setOpen] = useState(false);
  const [ytLink, setYtLink] = useState("")
  const cardData = [
    {
      id: 1,
      title: 'Deep Healing Meditation | Relaxing Meditation | Silva Method Guided Meditation Technique',
      description: "In this free meditation session, explore authentic techniques designed to help you heal, relax, overcome anxiety, and manage stress effectively. For over five decades, the Silva Method has empowered millions worldwide—including leaders, professionals, and individuals like you—to unlock the mind's potential and achieve personal breakthroughs.Start your journey toward a better tomorrow with our expert guidance and a global community of over 6 million meditators. 💡",
      videoUrl: 'https://silva-method-official.b-cdn.net/Deep%20Healing%20Meditation%20_%20Relaxing%20Meditation%20_%20Silva%20Method%20Guided%20Meditation%20Technique.mp4',
      img: "/img/sddefault.jpg",

    },
    {
      id: 2,
      img: "/img/sddefault2.jpg",
      description: "In this session, you'll discover effective meditation techniques to relax, reduce anxiety, and improve your mental health. With over five decades of experience, Silva Method has helped millions around the world achieve personal growth and well-being.Start your meditation journey today with our supportive global community.",
      title: "Deep Relax Meditation - 3 To 1 Method by José Silva - Silva Guided Meditation #1",
      videoUrl: "https://silva-method-official.b-cdn.net/Deep%20Relax%20Meditation%20-%203%20To%201%20Method%20by%20Jos%C3%A9%20Silva%20-%20Silva%20Guided%20Meditation%20%231%20(3).mp4"

    },
    {
      id: 3,
      img: "/img/sddefault (1).jpg",
      title: "Guided Sleep Meditation for Ultimate Relaxation & Deep Sleep | Silva Method Meditation",
      videoUrl: "https://silva-method-official.b-cdn.net/videoplayback.mp4",
      description: "In this brand-new session, you'll learn valuable techniques to help you relax, overcome stress, and promote mental well-being. Silva Method has been guiding people toward personal growth for over five decades, impacting millions around the world Start your journey to a calmer, more focused life today!"
    },
    {
      id: 4,
      img: "/img/sddefault (2).jpg",
      title: "Guided Sleep Meditation for Ultimate Relaxation & Deep Sleep | Silva Method Meditation",
      videoUrl: "https://silva-method-official.b-cdn.net/videoplayback%20(1).mp4",
      description: "In this new video, explore the powerful technique of self-hypnosis. Learn how to access the subconscious mind to create positive changes, reduce stress, and enhance your personal growth. Silva Method has helped millions unlock their potential through transformative techniques like these.Start mastering your mind today!"
    }
  ];


  return (
    <div className="container my-5">

      <Lightbox

        plugins={[Video, Fullscreen]}
        open={open}
        close={() => setOpen(false)}
        render={{
          iconClose: () => <AiOutlineClose size={40} color='#fff' />,
          iconEnterFullscreen: () => <BsArrowsFullscreen size={40} color='#fff' />,
          iconExitFullscreen: () => <BsFullscreenExit size={40} color='#fff' />,
        }}
        slides={[
          {
            type: "video",
            width: 1280,
            height: 720,
            autoPlay: true,
            sources: [
              {
                src: ytLink,
                type: "video/mp4"
              }
            ]
          },
          // ...
        ]}
    
      />

      <HeadingNew title={"Listen to these"} small />
      <HeadingNew title={"Guided "} colored={"Meditations"}  />

      <Row className="justify-content-center align-items-center mt-5 m-auto">
        {
          cardData.map((val) => (
            <Col xs={12} md={6} lg={4}>
              <Card className="card-obscure " style={{ width: isMobile?"100%":'95%', borderRadius: "15px" }}>
                <Card.Header
                  style={{
                    border: 'none',
                    background: 'white',
                  }}
                >
                  <img
                    className="card-img-obscure"
                    src={val.img}
                    alt="Meditation"
                    style={{
                      borderRadius:"10px"
                    }}
                  />
                </Card.Header>
                <Card.Body>
                  <h5 className='text-black fw-bold'>
                    {val.title}
                  </h5>




                  <p
                    className="robotofont text-muted mt-2 text-start "
                    style={{
                      fontSize: '0.9rem',
                      lineHeight: '1rem',
                    }}
                  >
                   {val.description}

                  </p>

                  <button className="btn btn-primary btn-obscure" onClick={() => {
                    setYtLink(val.videoUrl)
                    setOpen(true)
                  }}>Practice Now</button>
                </Card.Body>
              </Card>
            </Col>
          ))
        }



      </Row>
      <div 
      className="position-relative overflow-hidden p-4 p-md-6 rounded-3 shadow mt-5" 
      style={{
        background: 'rgba(138, 79, 255, 0.1)',
        border: '2px solid rgba(138, 79, 255, 0.3)',
        boxShadow: '0 0 30px rgba(138, 79, 255, 0.2)'
      }}
    >
      <div 
        className="position-absolute top-0 start-0 end-0 bottom-0 blur" 
        style={{
          background: 'radial-gradient(circle, rgba(138, 79, 255, 0.3) 0%, rgba(201, 100, 255, 0.2) 100%)',
          zIndex: -1,
          opacity: 0.5,
          filter: 'blur(50px)'
        }}
      ></div>
      
      <span 
        className="d-block text-center"
        style={{
          background: 'linear-gradient(to right, #8A4FFF, #C964FF)',
          WebkitBackgroundClip: 'text',
          backgroundClip: 'text',
          color: 'transparent',
          fontWeight: 'bold',
          fontSize: "2.5rem",
          position: 'relative',
          zIndex: 1
        }}
      >
        Be A Part of Our Daily Live Meditation Session
      </span>
    </div>
    </div>
  );
}

export default CardsThankyou;