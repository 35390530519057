import React, { useMemo } from 'react'
import Slider from 'react-slick';
import "./newlandingpage.css"
import HeadingComp from '../SilvaManifestationProgram/HeadingComp';
import HeadingNew from '../AboutUs/HeadingNew';
import ParaComp from '../SilvaManifestationProgram/ParaComp';
import { useMediaQuery } from 'react-responsive';
import HeadingNewBlackFriday from '../AboutUs/HeadingNewBlackFriday';
function Slider1ForBlackFriday({scrollTo}) {

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })


    const settings = {
        infinite: true,
        speed: 10000, // Controls the speed of the marquee
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, // Immediate slide transition to create marquee effect
        cssEase: 'linear', // Smooth transition
        variableWidth: true,
        arrows: false,
        pauseOnHover: true, // Optional: pause on hover
    };
    const settings2 = {
        infinite: true,
        speed: 7000, // Controls the speed of the marquee
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, // Immediate slide transition to create marquee effect
        cssEase: 'linear', // Smooth transition
        variableWidth: true,
        arrows: false,
        pauseOnHover: true, // Optional: pause on hover
    };



    const images = useMemo(() => {
        return [
            "/img/bg/bghero/problems/1.png",
            "/img/bg/bghero/problems/2.png",
            "/img/bg/bghero/problems/3.png",
            "/img/bg/bghero/problems/4.png",
            "/img/bg/bghero/problems/5.png",
            "/img/bg/bghero/problems/6.png",
            "/img/bg/bghero/problems/7.png",
            "/img/bg/bghero/problems/8.png",
            "/img/bg/bghero/problems/9.png",
            "/img/bg/bghero/problems/10.png"
        ]
    }, [])



    const images2 = useMemo(() => {
        return [
            "/img/bg/bghero/problems/11.png",
            "/img/bg/bghero/problems/12.png",
            "/img/bg/bghero/problems/13.png",
            "/img/bg/bghero/problems/Academic Pressure.png",
            "/img/bg/bghero/problems/Digestion Issues.png",
            "/img/bg/bghero/problems/Not Communicating with Parents.png",
            "/img/bg/bghero/problems/Parents' Separation Trauma on Children.png",
            "/img/bg/bghero/problems/Waking Up at the Right Time.png",
            "/img/bg/bghero/problems/Weight Gain for PCOD.png"


        ]

    }, [])

    return (
        <>

            <div style={{
                paddingTop: isMobile ? "20px" : "100px",
                paddingBottom: "20px",
                marginBottom: "30px",
                position: "relative",
                // marginTop: isMobile ? "20px" : "100px",
            }} className='my-5'>
                <div>
                    <div>
                        <HeadingNewBlackFriday  title={"Resolve Your Everyday Challenges"} />

                    </div>
                    <HeadingNewBlackFriday  title={"with The Silva Method"} small />
                    <ParaComp
                        data={[

                            <h5 className="mt-3 mb-3 robotofont " style={{
                                lineHeight: isMobile ? "1.3rem" : "1.5rem",
                                fontSize: isMobile ? "0.9rem" : "1rem"
                            }}>
                                Whether it’s dealing with exam pressure, past trauma, or navigating difficult family dynamics, the Silva Method provides proven tools to help you heal and move forward.

                            </h5>

                        ]}
                        margin={false}
                        white={false}
                        center
                    />

                </div>
                <div>
                    <img src='/img/bg/bghero/mobile-mockup.webp'
                        style={{
                            position: "absolute",
                            width: isMobile ? "50%" : "350px",
                            objectFit: "cover",
                            height: isMobile ? "auto" : "520px",
                            zIndex: 1,
                            left: "50%",
                            top: isMobile ? "75%" : "70%",
                            transform: "translate(-50%, -50%)",



                        }}
                    />
                </div>

                <div className='slider-newpage' style={{
                    marginTop: "60px"

                }}>
                    <Slider {...settings}>
                        {images.sort(() => Math.random() - 0.5).map((image, index) => (
                            <div key={index}>
                                <img
                                    src={image}
                                    alt={`slide-${index}`}
                                    style={{
                                        width: isMobile ? "100px" : "200px",
                                        height: isMobile ? "100px" : "200px",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                            border:"2px solid white"
                                    }}
                                />
                            </div>
                        ))}
                    </Slider>
                    <div style={{
                        marginTop: isMobile ? "0px" : "10px"
                    }}>
                        <Slider {...settings2}>
                            {images2.sort(() => Math.random() - 0.5).map((image, index) => (
                                <div key={index}>
                                    <img
                                        src={image}
                                        alt={`slide-${index}`}
                                        style={{
                                            width: isMobile ? "100px" : "200px",
                                            height: isMobile ? "100px" : "200px",
                                            objectFit: "cover",
                                            borderRadius: "10px",
                                            border:"2px solid white"
                                        }}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>

            </div>
            <div className='d-flex justify-content-center align-items-center mb-5'>
                <button className='bfs_cta2' onClick={scrollTo}>
                    Enroll Now
                </button>

            </div>
        </>
    )
}

export default Slider1ForBlackFriday