import React, { useContext } from 'react'
import Marquee from "react-fast-marquee";
import HeadingNew from '../../AboutUs/HeadingNew';
import "./marquee.css"
import { Button, Col, Row } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';
import { AuthContext } from '../../../context/AllContext';

const MeditationCard = ({ img, title, title2 }) => {
  return (
    <div className="m4n9_wrapper">
      <div className="k7p2_card" style={{
        backgroundImage: `url('${img}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}>

        <div className="w3r5_img_container" style={{
          position: "relative",
          zIndex: 1
        }}>
          <img
            src={img}
            alt="Meditation pose"
            className="y8h4_img"

          />
        </div>
        <div className="t6q1_content" style={{
          position: "relative",
          zIndex: 1
        }}>
          <h3 className="l5v7_title">{title}</h3>
          <h2 className="b9x3_subtitle">{title2}</h2>
        </div>
      </div>

    </div>
  );
};


function MarqueeSection() {
  const { isMobile } = useContext(AuthContext);

  const data = [
    {
      img: "/img/freeclass/destressstress-relief meditation.jpg",
      title: "De-stress/ Stress-relief",
      title2: "Meditation"
    },

    {
      img: "/img/freeclass/gratitude meditation.jpg",
      title: "Gratitute",
      title2: "Meditation"
    },
    {
      img: "/img/freeclass/healing meditation.jpg",
      title: "Healing",
      title2: "Meditation"

    },
    {
      img: "/img/freeclass/imaginationvisualisation meditation.jpg",
      title: "Imagination/ Visualisation",
      title2: "Meditation"

    },
    {
      img: "/img/freeclass/manifestation meditation.jpg",
      title: "Manifestation",
      title2: "Meditation"

    },
    {
      img: "/img/freeclass/mindfulness meditation.jpg",
      title: "Mindfulness",
      title2: "Meditation"
    },
    {
      img: "/img/freeclass/relaxation meditation.jpg",
      title: "Relaxation ",
      title2: "Meditation"
    }
  ]
  return (
    <>
      <div style={{
        backgroundImage: "url('/img/Image20241203161103.png')",
        backgroundPosition: "center",
        objectFit: "cover"
      }}>
        <div className='container py-5'>
          <Row className='mx-4 justify-content-center align-items-center' >
            <Col xs={12} md={6}>

              {
                isMobile ? (
                 
                    <>
                  <h4 className='adeliafont text-center' style={{
                    fontSize: "3rem",
                    textIndent:"-25px",
                    color: "#cc00ff"
                  }}>
                    Join our
                  </h4>

                  <h4 className='display-4 text-center arialfont mb-0'>
                  Silva Method

                  </h4>
                  <h2 class="display-2 antonfont text-center">DAILY MEDITATION</h2>
                  <h2 class="display-2 antonfont text-center" style={{
                    color:"#25D366"
                  }}>WHATSAPP GROUP</h2>

                 


                  <p className='robotofont text-black text-center'>
                    Be a part of our WhatsApp group and get daily updates, reminders, and inspiration!
                    Stay on track with your meditation journey!

                  </p>
                 <div className='d-flex justify-content-center align-items-center'>
                 <button
                    variant="success"
                    style={{
                      backgroundColor: '#25D366',
                      display: 'flex',
                      border:"none",
                      alignItems: 'center',
                      fontWeight:"bold",
                      borderRadius:"10px",
                      gap: '8px'
                    }}
                    className='px-4 py-2 white-color'
                    onClick={() => {
                      window.open("https://chat.whatsapp.com/IVIJBZ9FRCS4wYoe8KWe73")
                    }}
                  >
                    <FaWhatsapp color='white' />
                    Join Now
                  </button>

                 </div>
                  
                  </>
                ) : (
                  <>
                  <h4 className='adeliafont text-start' style={{
                    fontSize: "3rem",
                    textIndent:"-25px",
                    color: "#cc00ff"
                  }}>
                    Join our
                  </h4>

                  <h4 className='display-4 arialfont mb-0'>
                  Silva Method

                  </h4>
                  <h2 class="display-2 antonfont">DAILY MEDITATION</h2>
                  <h2 class="display-2 antonfont" style={{
                    color:"#25D366"
                  }}>WHATSAPP GROUP</h2>

                 


                  <p className='robotofont text-black'>
                    Be a part of our WhatsApp group and get daily updates, reminders, and inspiration!
                    Stay on track with your meditation journey!

                  </p>
                  <button
                    variant="success"
                    style={{
                      backgroundColor: '#25D366',
                      display: 'flex',
                      border:"none",
                      alignItems: 'center',
                      fontWeight:"bold",
                      borderRadius:"10px",
                      gap: '8px'
                    }}
                    className='px-4 py-2 white-color'
                    onClick={() => {
                      window.open("https://chat.whatsapp.com/IVIJBZ9FRCS4wYoe8KWe73")
                    }}
                  >
                    <FaWhatsapp color='white' />
                    Join Now
                  </button>
                </>
                )
              }


            </Col>
            <Col xs={12} md={6}>
             {
              isMobile? (
                <img src='/img/Image20241203153637.png' style={{
                  transform:"scale(1.2)"
                 }} />
              ):(
                <img src='/img/Image20241203153637.png' style={{
                  transform:"scale(1.5)"
                 }} />
              )
             }
            </Col>

          </Row>

        </div>
      </div>

      <div style={{
        background: "white"
      }} className='py-5 mb-5'>

        <HeadingNew title={"Daily Free Guided Meditation for Every Need "} start={false} />

        <Marquee pauseOnHover>
          {
            data.map((val) => (
              <MeditationCard img={val.img} title={val.title} title2={val.title2} />
            ))
          }
        </Marquee>

      </div>
    </>
  )
}

export default MarqueeSection





