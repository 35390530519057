import React, { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';

function ProductSection({scrollTo}) {
  const {isMobile} = useContext(AuthContext)
  return (
    <>
 
      <section className="banner_wrapper">
        <div className="banner_container">
          <div className="banner_content">
            <div className="banner_title white-color mb-0">Exclusive 60% OFF</div>
            <HeadingNew title={"Don't Miss Out. Steal the Deal"} small white />
          
            <button className="bfs_cta mt-3" onClick={scrollTo}>Grab the Offer</button>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="ps_container ps_container2">
          <div className="ps_image_col">
            <div className="ps_image_wrapper">
              <img
                src="/img/blackfriday/Smart meditation.webp"
                alt="Premium Headphones"
                className="ps_product_image"
              />
            </div>
          </div>
          <div className={isMobile?"ps_content_col container":"ps_content_col"}>
            <div className="ps_discount">60% OFF</div>
            <h3 className='text-black'>
              We Slashed the Prices For Limited Time!
            </h3>
            <HeadingNew title={"Meditate Smarter,Spend Less"} start />
            <h3 className='text-black text-start mb-3 mt-2'>Offer Valid till 30th Nov</h3>

            <button className="bfs_cta" onClick={scrollTo}>Enroll Now </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductSection;
