import React, { useContext, useState } from 'react';
import HeadingNewBlackFriday from '../../AboutUs/HeadingNewBlackFriday';
import { Col, Row } from 'react-bootstrap';
import useCountdown from './CountDown';
import { AuthContext } from '../../../context/AllContext';
import HighlightedPromo from './HighlightPromo';



export const GlowingBadge = () => {
    const styles = {
      container: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      },
      badge: {
        padding: '8px 16px',
        fontSize: '20px',
        fontWeight: '600',
        color: 'white',
        backgroundColor: '#000000',
        borderRadius: '25px',
        border: '2px solid #ff1dff',
        position: 'relative',
        zIndex: 2,
      },
      glow: {
        position: 'absolute',
        inset: '-4px',
        background: 'linear-gradient(45deg, #ff1dff, #660000)',
        borderRadius: '25px',
        opacity: 0.6,
        filter: 'blur(8px)',
        zIndex: 1,
        animation: 'pulse 2s infinite'
      },
      '@keyframes pulse': {
        '0%': {
          opacity: 0.6,
          transform: 'scale(1)'
        },
        '50%': {
          opacity: 0.8,
          transform: 'scale(1.05)'
        },
        '100%': {
          opacity: 0.6,
          transform: 'scale(1)'
        }
      }
    };
  
    return (
      <div style={styles.container}>
        <style>
          {`
            @keyframes pulse {
              0% {
                opacity: 0.6;
                transform: scale(1);
              }
              50% {
                opacity: 0.8;
                transform: scale(1.05);
              }
              100% {
                opacity: 0.6;
                transform: scale(1);
              }
            }
          `}
        </style>
        <div style={styles.glow}></div>
        <div style={styles.badge}>
        Step Confidently into 2025!
        </div>
      </div>
    );
  };

const BlackFridayHero2 = ({ scrollTo }) => {
    const {isMobile} = useContext(AuthContext);


    const [time, setTime] = useState({ hours: '00', minutes: '00', seconds: '00' });
    const { days, hours, minutes, seconds, isActive } = useCountdown("2024-11-30")

    return (
        <div className='bfs_wrapper'>
            <div className='container'>
                <Row className='justify-content-center align-items-center'>
                    <Col xs={12}>



                        <div className={'d-flex justify-content-center align-items-center flex-column'}>
                            <img src='/img/footer/footer-03.webp' className='my-2' style={{
                                maxWidth: "300px"
                            }} />

                            <div className="bfs_sale_container">
                                <div className="bfs_sale_inner">
                                    <h1>
                                        <span className="bfs_title_accent ">BLACK FRIDAY</span>
                                        <span className={"bfs_title_script text-center"}>Sale</span>
                                    </h1>

                                    <img
                                        src="/img/blackfriday/COUPON.webp"
                                        alt="50% Off"
                                        className="bfs_badge"
                                        style={{
                                            left:isMobile?"65%":"none",
                                            right:"18%",
                                            top:"75%"
                                        }}
                                    />
                                </div>
                            </div>



                         
                                    <div style={{
                                        paddingTop:isMobile?"20px":"0px"
                                    }}>
                                    <HeadingNewBlackFriday title={"THE SALE YOU’VE BEEN WAITING FOR YEARS"} white small />
                                    {/* <p className='robotofont white-color text-center mt-3'>
                                     Flat 60% Off on The Complete Silva Method Program
                                    </p>
                                    <p className='robotofont white-color text-center'>
                                        Get Lifetime Access to  8 + Original Silva Method Courses with Free Live Interactive Classes
        
                                        Just at $ 297 ($750)
                                    </p>
                                    <p className='robotofont white-color text-center'>
                                        <i>
                                            Offer valid until 30th November.
                                        </i>
        
                                    </p> */}
                                    <HighlightedPromo />
                                    </div>
        
                               

                            <div className="bf_timer_v8">
                                <div className="bf_box_u3">
                                    <span className="bf_digits_t7">{days}</span>
                                    <span className="bf_label_s1">DAYS</span>
                                </div>
                                <div className="bf_box_u3">
                                    <span className="bf_digits_t7">{hours}</span>
                                    <span className="bf_label_s1">HOURS</span>
                                </div>
                                <div className="bf_box_u3">
                                    <span className="bf_digits_t7">{minutes}</span>
                                    <span className="bf_label_s1">MINUTES</span>
                                </div>
                                <div className="bf_box_u3">
                                    <span className="bf_digits_t7">{seconds}</span>
                                    <span className="bf_label_s1">SECONDS</span>
                                </div>
                            </div>
                            <button className='bfs_cta mt-4' onClick={scrollTo}>
                                Enroll Now

                            </button>
                        </div>
                    </Col>


                </Row>

            </div>

        </div>

    );
};

export default BlackFridayHero2;