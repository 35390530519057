import React, { useContext, useEffect } from 'react'
import Footer from '../../../components/Shared/Footer'
import NewHeader from '../../AdvertisePages/newmembership/NewHeader'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import VideoBackground from './VideoBackground'
import { Image } from 'react-bootstrap'
import TrustedBy from '../../Draganpages/TrustedBy'
import FormSection from './FormSection'
import "./video-background.css"
import BenefitsFree from './BenefitsFree'
import BenifitSectionHyp from '../HypnosisPage/BenefitsSectionHyp'
import ReviewsContainer from '../../../components/DifferenceSection/ReviewsContainer'
import BreakThroughs2 from '../ComboCourse2/BreakThroughs2'
import ComboContactSection from '../ComboCourse2/ComboContactSection'
import NewFooter from './NewFooter'
import NewVideoSection from './NewVideoSection'
import MarqueeSection from './MarqueeSection'
import FlipCountdownTimer from './CountDownTimer'
import MeditationSection from './CountDownSection'
import { AuthContext } from '../../../context/AllContext'
import TimerSection from './TimerSection'
import InstructorSection2 from './InstructorSection2'

function FreeCourse() {

  const {isMobile}= useContext(AuthContext);


  return (
    <>
        <NewHeader />
        {/* <VideoBackground /> */}
        <NewVideoSection />
        <TrustedBy />
        <div className='newsec'>

        <FormSection />

        <InstructorSection2 />

        <TimerSection />
        {/* <MeditationSection/> */}
       {/* {!isMobile && <img src='/img/bg/freewpgroupqr.jpg' />}
       {isMobile && <img src='/img/bg/freewpgroupqr.png' />} */}
        </div>
        {/* <BenefitsFree /> */}
        
        <MarqueeSection />

        <BenifitSectionHyp btnlink />
        <div style={{
          paddingTop:"50px",
          paddingBottom:"50px"
        }}>
        <BreakThroughs2 btnbtn />
        </div>

        <ReviewsContainer />
      

        <div className='py-5'>
          <ComboContactSection />

        </div>
        {/* <CustomFooter /> */}
        <NewFooter />
    </>
  )
}

export default FreeCourse