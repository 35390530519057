import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Row, Col, Card, Spinner, Image } from 'react-bootstrap';
import HeadingNew from '../AboutUs/HeadingNew';
import { FaCcPaypal, FaLock, FaStripe } from 'react-icons/fa';
import { AuthContext } from '../../context/AllContext';
import {
    reqData, requestData, REACT_BASE_URL,
    domainUrl,
} from '../../utils/baseUrl';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import NewPaymentCard from '../SingleCoursePage/NewPaymentCard';

function HomePageHeroSection() {
    const navigate = useNavigate()

    const [hasRun, setHasRun] = useState(false)

    // const {isDesktopOrLaptop,isBigScreen,isMediumDesktop,isNormalDesktop} = useContext(AuthContext);

    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })

    const isMediumDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1365 })

    const isMobile = useMediaQuery({ minWidth: 375, maxWidth: 767 })
    const isLargeDesktop = useMediaQuery({ query: '(min-width: 1366px)' })


    const isSmallMobile = useMediaQuery({ query: '(max-width: 374px)' })


    // const isNewDesktop = 




    const [userEmail, setUserEmail] = useState("");
    const [defaultPayment, setDefaultPayment] = useState("Stripe");
    const formRef = useRef(null);

    const [validCoupon, setValidCoupon] = useState()
    const location = useLocation();
    const token = location?.state?.token;
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');

    const btnRef = useRef(null);
    const [courseData, setCourseData] = useState([]);
    const [coupon, setCoupon] = useState("" || token || tokenParam);

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);


    const [priceplan2, setPricePlan2] = useState()

    const { userData, setUserData } = useContext(AuthContext);

    const [studentId, setStudentId] = useState("");
    const [paypalOrderID, setPaypalOrderID] = useState("");

    const getCourseData = async () => {
        setLoading(true);
        const res = await reqData("courseDetail", "POST", {
            course_id: "combo-plan",
        });

        // console.log(res.data[0].course_description, "Response")


        setLoading(false);
        if (res.error === false) {
            setCourseData(res.data);
        }
    };


    useEffect(() => {
        if (token) {
            setCoupon(token);
        }
    }, [token]);


    useEffect(() => {
        if (tokenParam && coupon === "") {
            setCoupon(tokenParam)
        }
    }, [location.search, tokenParam, searchParams]);




    useEffect(() => {
        // console.log(paramCoupon,'paramcoupon')
        if (coupon && (token || tokenParam) && courseData && courseData[0] && !hasRun) {
            btnRef?.current?.click();
            setHasRun(true)
        }
    }, [coupon, courseData]);

    const slides = [
        {
            background: "/img/bg/bghero/header-slider-BG-copy.webp",
            largeText1: "Silva Method",
            largeText2: " Complete Program",


            belowtext1: "All Tools and Techniques Now with Live Interactive Sessions",
            belowtext2: <h5 className='white-color mt-3' style={{
                fontWeight: "400",
                margin: "0",
                padding: "0",
                lineHeight: "20px",
                textAlign: "start",

            }}>
                Get All Silva Method Courses in Package + Live Classes for
                <span style={{
                    color: "whitesmoke",
                    fontWeight: "600",
                    marginLeft: "5px",
                    paddingBottom: "2px",
                    borderBottom: "1px solid white"
                }}>
                    $0 Extra
                </span>
            </h5>,
            title: "All Tools and Techniques Now with Live Interactive Sessions",
            text: "Get All Silva Method Courses in Package + Live Classes for $0 Extra",
            imgComp: (
                <div className='d-flex justify-content-start align-items-center gap-3 mt-3'>

                    <img

                        // src="/img/bg/bghero/slider-1-course-packges-copy.webp"
                        src='/img/bg/Image20240829111956.jpg'

                        style={{
                            height: isMobile ? "auto" : isSmallMobile ? "auto" : isMediumDesktop ? "250px" : "auto",
                            borderRadius: 15,
                            border: "2px dashed white",
                            marginTop: !isMobile ? "10px" : "0px"


                        }} />


                </div>
            )
        },
        {
            largeText1: "Dive into Live ",
            largeText2: "Instructor-Led Classes",
            belowtext1: "At  Zero Extra Cost",
            belowtext2: <h5 className='white-color mt-3' style={{
                fontWeight: "400",
                margin: "0",
                padding: "0",
                lineHeight: "20px",
                textAlign: "start",

            }}>
                Actual Worth

                <span style={{
                    color: "whitesmoke",
                    fontWeight: "600",
                    marginLeft: "5px",
                    paddingBottom: "2px",

                    borderBottom: "1px solid white"


                }}>
                    $2400/Annually
                </span>
            </h5>,
            background: "/img/bg/bghero/Image20241105181459.jpg",
            title: "Dive into Live Instructor Classes At Zero Extra Cost",
            text: "Actual Worth $2400/Annually",
            imgComp: (
                <div className='d-flex justify-content-start align-items-center gap-3 mt-4'>
                    <img
                        // src="/img/bg/bghero/header-slider-instructor-copy.webp"
                        src='/img/Image20241112183728.jpg'

                        style={{
                            height: isMobile ? "auto" : isSmallMobile ? "auto" : isMediumDesktop ? "250px" : "auto",
                            borderRadius: 15,
                            border: "2px dashed white",
                            marginTop: !isMobile ? "10px" : "0px"

                        }} />


                </div>
            )
        },
        {
            largeText1: "All the Silva Method ",
            largeText2: "Courses You Need",
            belowtext1: "6 Courses in One Package+ Weekly Live Sessions",
            belowtext2: <h5 className='white-color mt-3' style={{
                fontWeight: "400",
                margin: "0",
                padding: "0",
                lineHeight: "20px",
                textAlign: "start",
                fontSize: "1rem"

            }}>
                Heal, Manifest, and Elevate with

                Silva's All-in-One Program.
                Your Mind Mastery Begins Here!

            </h5>,
            background: "/img/bg/bghero/header-slider-BG-copy.webp",
            title: "All the Silva Method You Need",
            text: "8 Courses in One Package Your Mind Mastery Begins Here!",
            imgComp: (

                <div className='d-flex justify-content-start align-items-center gap-3 mt-4'>
                    <img
                        // src={isSmallMobile?"/img/bg/bghero/header-slider-COURSES-FOR-MOBILE.webp":"/img/bg/bghero/header-slider-COURSES.webp"}
                        src={(isSmallMobile) ? "/img/bg/bghero/header-slider-COURSES-FOR-MOBILE.webp" : isLargeDesktop ? "/img/bg/bghero/header-slider-COURSES-FOR-MOBILE.webp" : "/img/bg/bghero/header-slider-COURSES-FOR-MOBILE.webp"}

                        style={{
                            // height: (isTablet || isMobile) ? "auto" : isSmallMobile ? "auto" : "200px",
                            width: isSmallMobile ? "100%" : isMobile ? "80%" : (isMediumDesktop || isLargeDesktop) ? "80%" : "70%",



                            borderRadius: 15,


                        }} />


                </div>
            )
        }
    ];

    const settings = {
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        autoplaySpeed: 5000,
        fade: true, // Use fade effect for smooth transitions
        arrows: false,
        pauseOnHover: false



    };
    const handleApplyCoupon = async () => {
        if (!coupon) {
            toast.error("Please enter a coupon !");
            return;
        }
        const res = await requestData("applyCouponCode", "POST", {
            coupon_code: coupon,
        });

        if (res && res.error === false) {
            const validCoupon = moment(new Date(Date.now())).isBetween(
                res.data.start_date,
                res.data.end_date
            );

            let priceplan;

            // console.log(datanew,'data')

            const originalPrice = Number(courseData[0]?.price);


            // console.log(originalPrice,'originalPrice')

            // Discount percentage
            const discountPercentage = Number(courseData[0]?.discount_percentage) || 0;

            // console.log( typeof discountPercentage,'dscp')

            // Calculating the discount amount
            const discountAmount =
                (discountPercentage * parseInt(originalPrice)) / 100;

            // Calculating the discounted price
            const discountedPrice = Number(
                Math.floor(originalPrice - discountAmount).toFixed(2)
            );

            // Result
            // console.log(discountedPrice);
            priceplan = discountedPrice;

            // }

            // setPricePlan2(priceplan)
            if (validCoupon) {
                // reactLocalStorage.set("coupon", coupon2)
                // reactLocalStorage.set("couponplan", 3)
                // reactLocalStorage.setObject("value",val)
                const newdis =
                    priceplan - priceplan * (Number(res?.data?.discount_percentage) / 100);


                // const found = plans.find(p=>p.cycle===cycle)
                if (newdis == priceplan) {
                    toast.error("Coupon already applied", {
                        position: "top-center",
                    });
                    return;
                }

                let finalPrice = Math.ceil(newdis.toFixed(2));


                // console.log(Math.floor(newdis.toFixed(2)),'newdis')

                setPricePlan2(finalPrice);
                setValidCoupon(coupon);

                // setPricePlan1(Number(plans[0].price).toFixed(2))

                toast.success("coupon applied", {
                    position: "top-center",
                });
            } else {
                toast.error("Coupon is not valid !!", {
                    position: "top-center",
                });
            }
        } else {
            toast.error(res.messages, {
                position: "top-center",
            });
        }
    };

    const handleSubscription = async () => {
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userEmail);
        if (!isValidEmail) {
            toast.error("Please enter a valid email");
            return;
        }

        if (defaultPayment === "Stripe") {
            handleSubscriptionStripe();
        } else {
            handleSubscriptionPaypal();
        }
    };

    const handleSubscriptionPaypal = async () => {
        const data = {
            course_id: courseData[0].course_id,
            email: userEmail,
            payment_gatway: defaultPayment,
        };


        if (validCoupon) {
            data.coupon_code = validCoupon;
        }
        setLoading(true);
        const res = await requestData("enrollNow", "POST", data);

        // localStorage.setItem("token",res.student_id)

        if (res && res.error === false && res.status === 200) {
            setPaypalOrderID(res.order_id);
            setStudentId(res.student_id);
        } else {
            toast.error(res.messages);
        }


        setLoading(false);


    };

    const handleSubscriptionStripe = async () => {
        const data = {
            course_id: courseData[0].course_id,
            email: userEmail,
            payment_gatway: defaultPayment,
        };


        if (validCoupon) {
            data.coupon_code = validCoupon;
        }
        setLoading(true);
        const res = await requestData("enrollNow", "POST", data);
        // localStorage.setItem("token",res.student_id)
        setLoading(false);

        if (res && res.title === "StripeExceptionInvalidRequestException") {
            toast.error("You are already subscribed to stripe");
            return;
        }

        if (res && res.error === true) {
            toast.error(res.messages);
            return;
        }

        if (res && res.error === false) {
            window.location.assign(res.data);
        }
    };
    useEffect(() => {
        getCourseData();
        if (userData && userData.email) {
            setUserEmail(userData.email);
        }


        if (userData !== null) {
            if (userData && userData.enrollment_status !== "Enrolled") {
                setShow(true)
            } else if (userData && userData.enrollment_status === "Enrolled") {
                setShow(false)
            }
        } else {
            setShow(true)
        }
    }, [userData]);


    useEffect(() => {
        if (courseData[0]) {
            setPricePlan2(
                Math.floor(
                    courseData[0]?.price -
                    ((courseData[0]?.discount_percentage || 0) * parseInt(courseData[0]?.price)) /
                    100
                ).toFixed(2)
            );
        }
    }, [courseData]);



    useEffect(() => {
        if (!location.state) {
            setCoupon("");
        }

        if (courseData[0] && courseData[0]?.course_id) {
            const course_id = courseData[0].course_id;

            if (course_id === "combo-plan") {

                setPricePlan2(
                    Math.floor(
                        courseData[0]?.price -
                        ((courseData[0]?.discount_percentage || 0) *
                            parseInt(courseData[0]?.price)) /
                        100
                    ).toFixed(2)
                );

            }
        }
    }, [courseData]);


    useEffect(() => {
        if (paypalOrderID && studentId) {
            if (formRef.current) {
                formRef.current.submit();
            }
        }
    }, [paypalOrderID, studentId]);

    return (

        <div style={{
            position: 'relative',
            overflow: 'hidden',
            height: (userData && isMobile && userData.enrollment_status === "Enrolled") ? "80vh" : (isMobile) ? "1250px" : isSmallMobile ? "1250px" : isMediumDesktop ? "auto" : (isTablet) ? "1500px" : "auto",
        }}
            className='my-5'

        >

            {/* <video src='https://silva-method-official.b-cdn.net/avijit-silva-intl/bg-video.mp4' autoPlay muted loop playsInline style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: 1,
            }}
                poster='/img/bg/bghero/header-slider-BG-copy.webp'
            /> */}

            <video src='/videos/bg.mp4' autoPlay muted loop playsInline style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: 1,
            }}
                poster='/img/bg/bghero/header-slider-BG-copy.webp'
            />

            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: "rgba(0,0,0,0.2)",
                zIndex: 2
            }}>

            </div>



            <div className='container' style={{
                position: "relative",
                zIndex: 3
            }}>
                <Row className={isTablet ? 'justify-content-center align-items-center gap-5' : 'justify-content-center align-items-center'} noGutters style={{


                    // paddingLeft: (isTablet) ? "30px" : isMobile ? "0px" : "30px",
                    paddingTop: (isMobile || isSmallMobile) ? "30px" : isTablet ? "30px" : (isMediumDesktop) ? "50px" : "70px",
                    paddingBottom: (isMobile || isTablet || isSmallMobile) ? "20px" : isMediumDesktop ? "100px" : "50px",
                    height: (userData && isMobile && userData.enrollment_status === "Enrolled") ? "80vh" : (isMobile) ? "1250px" : isSmallMobile ? "1250px" : (isMediumDesktop) ? "auto" : isTablet ? "1600px" : "auto",
                }}>
                    <Col lg={7}>






                        <div className='container herosectionslide' style={{
                            height: (isMediumDesktop || isTablet || isMobile) ? "260px" : isSmallMobile ? "450px" : "auto",
                            marginBottom: isMediumDesktop ? "50%" : isTablet ? "40px" : "0"
                        }}>

                            <Slider {...settings} >
                                {
                                    slides.map((slide) => {
                                        return (
                                            <div>
                                                {
                                                    slide.largeText1 && slide.largeText2 && isMobile && isSmallMobile && (
                                                        <>
                                                            <HeadingNew title={slide.largeText1 + " " + slide.largeText2} start white />
                                                        </>
                                                    )
                                                }

                                                {
                                                    slide.largeText1 && slide.largeText2 && (!isMobile || !isSmallMobile) && (
                                                        <>
                                                            <HeadingNew title={slide.largeText1} start white />
                                                            <HeadingNew title={slide.largeText2} start white />

                                                        </>
                                                    )
                                                }
                                                {
                                                    slide.belowtext1 && (
                                                        <h5 className='white-color mt-2' style={{
                                                            fontWeight: "500",
                                                            margin: "0",
                                                            textAlign: "start",
                                                            fontSize: "1.2rem",
                                                            padding: "0",
                                                            lineHeight: "25px"
                                                        }}>
                                                            {slide.belowtext1}
                                                        </h5>
                                                    )
                                                }
                                                {
                                                    slide.belowtext2 && (
                                                        <h5 className='white-color mt-2' style={{
                                                            fontWeight: "400",
                                                            margin: "0",
                                                            padding: "0",
                                                            lineHeight: "25px",

                                                            textAlign: "start",

                                                        }}>
                                                            {slide.belowtext2}
                                                        </h5>
                                                    )
                                                }

                                                {

                                                    slide.imgComp

                                                }
                                            </div>

                                        )
                                    })
                                }
                            </Slider>

                        </div>








                    </Col>
                    <Col lg={5}
                        style={{ zIndex: 2 }}>


                        <div
                            className='d-flex justify-content-center align-items-center'
                            style={{
                                marginTop: isMobile ? "100px" : "20px",

                            }}
                        >
                            {
                                userData && isMobile && userData.enrollment_status === "Enrolled" && (
                                    <button style={{
                                        marginTop: 70
                                    }} className='cssbuttons-io-button5' onClick={() => {
                                        navigate("/today")
                                    }}>View my courses</button>
                                )
                            }

                            {
                                courseData && courseData[0] && show === true && (
                                    <Card className="shadow newpaymentcard" style={{
                                        borderRadius: "15px",
                                        width: isMobile ? "95%" : "90%",
                                        background: "whitesmoke"

                                    }}>

                                        <Card.Body style={{
                                            background: "white",
                                            borderRadius: "15px",

                                        }}>
                                            <Card.Img src={courseData[0]?.web_image} style={{
                                                borderRadius: "5px",
                                                marginBottom: "10px",
                                                height: "auto",
                                                objectFit: "cover"
                                            }} />

                                            <div className={`${isMobile ? 'd-flex justify-content-start align-items-center flex-wrap' : 'd-flex justify-content-start align-items-center flex-wrap'}`}>


                                                <h3 style={{
                                                    fontWeight: "bold",
                                                    fontSize: "2.5rem"
                                                }}>${priceplan2} <s>${courseData[0]?.price}</s> </h3>
                                                <p style={{
                                                    fontWeight: "600",
                                                    padding: "0",
                                                    margin: "0",
                                                    marginBottom: isMobile ? "20px" : "0px",
                                                    marginLeft: isMobile ? "0px" : isSmallMobile ? "0px" : "20px"

                                                }}>You will get {" "}
                                                    {Math.floor(
                                                        ((courseData[0].price - priceplan2) / courseData[0].price) * 100
                                                    )}
                                                    % off</p>

                                            </div>

                                            <div>
                                                <label className='mb-1' style={{
                                                    color: "black",
                                                    marginTop: isSmallMobile ? "10px" : (isMediumDesktop || isLargeDesktop) ? "10px" : "auto"
                                                }}>Do you have a coupon code</label>
                                                <div className="input-container">
                                                    <input required="" placeholder="Enter coupon" type="email"
                                                        value={coupon}
                                                        onChange={(e) => setCoupon(e.target.value)}
                                                    />
                                                    <button className="invite-btn" type="button" onClick={handleApplyCoupon}
                                                        ref={btnRef}>
                                                        Apply Coupon
                                                    </button>

                                                </div>
                                            </div>
                                            <div className='my-2'>
                                                <label style={{
                                                    color: "black"
                                                }}
                                                    className='mb-1'
                                                >Enter your email id <span style={{
                                                    color: "red"
                                                }}>
                                                        *
                                                    </span></label>
                                                <div className="input-container">
                                                    <input required="" placeholder="Your Email Id" type="email"
                                                        value={userEmail}
                                                        onChange={(e) => setUserEmail(e.target.value)}
                                                    />


                                                </div>
                                            </div>


                                            <div className='mt-3 mb-1'>
                                                <p style={{
                                                    fontSize: "1rem",
                                                    lineHeight: "20px",
                                                    padding: 0,
                                                    margin: 0,
                                                    marginBottom: "5px"
                                                }}>Your preferred payment method</p>


                                                <div className={isSmallMobile ? "d-flex justify-content-start align-items-center flex-wrap gap-2" : 'd-flex justify-content-start align-items-center gap-4'}>
                                                    <div class="checkbox-wrapper-33">
                                                        <label class="checkbox">
                                                            <input class="checkbox__trigger visuallyhidden" type="checkbox"
                                                                checked={defaultPayment === "Stripe"}
                                                                onChange={() => setDefaultPayment("Stripe")}
                                                            />
                                                            <span class="checkbox__symbol">
                                                                <svg aria-hidden="true" class="icon-checkbox" width="28px" height="28px" viewBox="0 0 28 28" version="1" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4 14l8 7L24 7"></path>
                                                                </svg>
                                                            </span>
                                                            <p class="checkbox__textwrapper">
                                                                <span style={{ fontWeight: "600" }}>
                                                                    Pay with <FaStripe color="#655fff" size={40} />
                                                                </span>
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div class="checkbox-wrapper-33">
                                                        <label class="checkbox">
                                                            <input class="checkbox__trigger visuallyhidden" type="checkbox"
                                                                checked={defaultPayment === "Paypal"}
                                                                onChange={() => setDefaultPayment("Paypal")}
                                                            />
                                                            <span class="checkbox__symbol">
                                                                <svg aria-hidden="true" class="icon-checkbox" width="28px" height="28px" viewBox="0 0 28 28" version="1" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4 14l8 7L24 7"></path>
                                                                </svg>
                                                            </span>
                                                            <p class="checkbox__textwrappers d-flex justify-content-center align-items-center" style={{
                                                                margin: "0px"
                                                            }}>
                                                                <span style={{ fontWeight: "600" }}>
                                                                    Pay with    <Image
                                                                        fluid
                                                                        src={require("../../images/paypal-logo-preview.png")}
                                                                        style={{ width: "auto", height: "18px" }}
                                                                    />
                                                                </span>
                                                            </p>
                                                        </label>
                                                    </div>

                                                </div>



                                            </div>

                                            <div className="secureserver">
                                                <span>
                                                    <FaLock size={15} />
                                                </span>

                                                <p style={{ fontSize: "0.8rem" }}>
                                                    For your security all orders are processed on a secure server
                                                </p>
                                            </div>
                                            {defaultPayment === "Paypal" && (
                                                <form
                                                    id="form"
                                                    action={process.env.REACT_APP_PAYPAL_URL}
                                                    method="post"
                                                    ref={formRef}
                                                >
                                                    <input
                                                        type="hidden"
                                                        name="business"
                                                        value={process.env.REACT_APP_BUSINESS_EMAIL}
                                                    />
                                                    <input type="hidden" name="cmd" value="_xclick" />
                                                    <input
                                                        type="hidden"
                                                        name="item_name"
                                                        value={"Combo with live interactive"}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name="item_number"
                                                        value={"combo-with-live"}
                                                    />
                                                    <input type="hidden" name="amount" value={priceplan2} />
                                                    <input type="hidden" name="currency_code" value="USD" />
                                                    <input type="hidden" name="custom" value={paypalOrderID} />
                                                    <input
                                                        type="hidden"
                                                        name="cancel_return"
                                                        value={`${REACT_BASE_URL}subscription-failed?q=${btoa(
                                                            paypalOrderID
                                                        )}&s=${btoa(studentId)}`}

                                                    />

                                                    <input
                                                        type="hidden"
                                                        name="return"
                                                        value={`${REACT_BASE_URL}subscription-success?q=${btoa(
                                                            paypalOrderID
                                                        )}&s=${btoa(studentId)}&pg=${btoa("paypal")}`}

                                                    />

                                                    <input
                                                        type="hidden"
                                                        name="notify_url"
                                                        value={`${domainUrl}/paypal-notify`}
                                                    />
                                                    <input type="hidden" name="auto_return" value="all" />
                                                    <input
                                                        type="hidden"
                                                        className="herodiv-becomememberbtn3"
                                                        value={"Enroll Now"}
                                                    />
                                                </form>
                                            )}

                                            <button class="cssbuttons-io-buttonnew" onClick={handleSubscription}
                                                disabled={loading}
                                            >

                                                <span>
                                                    {loading ? (
                                                        <Spinner animation="border" variant="dark" />
                                                    ) : (
                                                        "Enroll Now"
                                                    )}

                                                </span>
                                            </button>
                                        </Card.Body>
                                    </Card>
                                )
                            }

                        </div>


                    </Col>
                </Row>

            </div>
        </div>


    );
}

export default HomePageHeroSection;
