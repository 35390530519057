import React, { useState, useEffect, useContext } from 'react';
import { checkIsCoursePurchased, requestData } from '../../../utils/baseUrl';
import LoadingSpinner from '../../LoadingSpinner';
import { useMemo } from 'react';
import HeadingNew from '../../AboutUs/HeadingNew';
import { AuthContext } from '../../../context/AllContext';
import { Image, Offcanvas } from 'react-bootstrap';
import CourseAccodions from '../../CoursePage/CourseAccodions';
import { IoIosClose } from "react-icons/io";
import ShineBtn from './ShineBtn';

function ComboIncluded({ func }) {
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [show, setShow] = useState(false);
    const st_id = localStorage.getItem("token")

    const { userData: udata, isMobile } = useContext(AuthContext);

    const [isCurrentCoursePurchased, setIsCurrentCoursePurchased] = useState(false);

    const handleClose = () => {
        setShow(false)
        setCourseDetails(null)
    };
    const handleShow = () => setShow(true);

    const [courseDetails, setCourseDetails] = useState(null);

   




    const getCourses = async () => {
        setLoading(true);
        const res = await requestData("courseListWithChild", "POST", {
            start_index: 0,
            no_of_records: 20
        });
        setLoading(false);

        const filtered = res.data.filter((cc)=>cc.is_combo_course==="Yes")
        let included = filtered.length>0? filtered[0].included_course.map((val)=>val.course_id):[]
        console.log(included,"filtered")

        if (res && res.error === false) {
            res.data.forEach((value, i) => {
                const userenrolledcourses = udata ? udata.enrolled_course.map((val) => val.course_id) : []

                if (udata && udata.enrollment_status === "Enrolled" && (userenrolledcourses.includes(value.course_id)
                    || userenrolledcourses.length === Number(udata.total_course))) {
                    setIsCurrentCoursePurchased(true)
                } else {
                    setIsCurrentCoursePurchased(false)
                }
            })
            const courses = res.data.filter((cc)=>{
                console.log(cc.course_id,"cc.course_id")
                console.log(included.includes(cc.course_id),"cc.course_id",included)
                return included.includes(cc.course_id)
            })
            setCourses(res?.data?.sort((a, b) => Number(a.precedence) - Number(b.precedence)).filter((val, i) => {
                return !["combo-plan", "silva-method-complete-course-with-live-interactive-program", "unstress-toolbox","the-silva-harmonious-relationships","the-silva-mind-control-sound"].includes(val.course_id)
            }));
        }


    }

    useEffect(() => {
        getCourses();
        // getPurchasedStat(udata)
    }, []);



    useEffect(() => {
        if (courseDetails) {
            handleShow()
        }
    }, [courseDetails])

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <div className='pt-5 pb-4'>
                <HeadingNew title={"Courses included in the"} small />
                <HeadingNew title={"Silva Method"} colored={"Combo Course"} />

                <div className="container containercombonew">
                    {courses.map((val, index) => (
                        <div className="wrapperbro" key={index}>
                            <div className="cardbro">
                                <img src={val.web_image} alt={`Image ${index + 1}`} onClick={() => {
                                    setCourseDetails(null)
                                    // getCourseDetails(val.course_id)
                                    const found = courses.find((value, i) => {
                                        return value.course_id === val.course_id
                                    })

                                    if (found) {
                                        setCourseDetails(found)
                                    }

                                }} />
                                <div className="arrowIcon d-flex gap-2 align-items-center" onClick={() => {
                                    setCourseDetails(null)
                                    // getCourseDetails(val.course_id)
                                    const found = courses.find((value, i) => {
                                        return value.course_id === val.course_id
                                    })

                                    if (found) {
                                        setCourseDetails(found)
                                    }

                                }}>
                                    <i className="fal fa-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <ShineBtn onClick={func} text={"Enroll Now to Unlock All Benefits"} />
                </div>
            </div>
            {
                courseDetails && (
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton className='d-flex justify-content-between'>
                            <Offcanvas.Title className='white-color'>{courseDetails?.course_title}</Offcanvas.Title>
                            <span>
                                <IoIosClose color='white' size={40} onClick={handleClose} />

                            </span>


                        </Offcanvas.Header>
                        <Offcanvas.Body style={{
                            padding: isMobile ? "0 10px 0 10px" : "0 0 0 10px"
                        }}>
                            <div style={{
                                paddingRight: "15px"
                            }}>
                                <Image src={courseDetails.web_image} style={{
                                    borderRadius: "10px"
                                }} />
                            </div>
                            {courseDetails && (
                                <CourseAccodions
                                    lastCourse={false}
                                    data={courseDetails}
                                    isCurrentCoursePurchased={isCurrentCoursePurchased}
                                    whitetitle
                                    searchoff
                                />
                            )}


                        </Offcanvas.Body>
                    </Offcanvas>
                )
            }

        </>
    );
}

export default ComboIncluded;
