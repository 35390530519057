import React from 'react'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import BlackFridayHeroSection from './BlackFridayHeroSection'
import "./BlackFridaySection.css"
import ProductSection from './ProductSection'

import Slider1ForBlackFriday from '../../CoursePage2/Slider1ForBlackFriday'

import SilvaComboFAQ2 from '../ComboCourse/SilvaComboFAQ2'
import HeadingNewBlackFriday from '../../AboutUs/HeadingNewBlackFriday'
import BecomeMember2 from '../../Home/Home/BecomeMember2'
import BlackFridayHero2 from './BlackFridayHero2'
import { useNavigate } from 'react-router-dom'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'
import TestComp from './TestComp'

function BlackFridaySale() {
  const navigation = useNavigate()

  const scrollTo = () => {
    polyfill()
    const doc = document.querySelector(".newpaymentcard");
    setTimeout(() => {

      scrollIntoView(doc, {
        behavior: "smooth",
        block: "end", inline: "nearest"
      });
    }, 300)
  }

  const handleClick = () => {
    // if (location.pathname === "/store/course/combo-plan") {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   urlParams.set('token', 'BLACKFRIDAY');
    //   window.location.search = urlParams;
    // } else {


    // }

    navigation('/blackfridaysale?token=NEWYEAR60')
    scrollTo()
  }
  return (
    <div>
      <HomeHeader />

      <BlackFridayHero2 scrollTo={handleClick} />
      <Slider1ForBlackFriday scrollTo={handleClick} />

      <BlackFridayHeroSection scrollTo={handleClick} />
      <ProductSection scrollTo={handleClick} />


      {/* <NewHomeHeroSection /> */}


      <div className='ps_wrapper'>
        <div className='container py-5'>
          <HeadingNewBlackFriday title={"Frequently Asked Questions"} white />
          <SilvaComboFAQ2 />


        </div>
      </div>


      <BecomeMember2 scrollTo={handleClick} />

      <CustomFooter />
    </div>
  )
}

export default BlackFridaySale