import React from 'react'
import FAQ2 from '../CoursePage/FAQ2'

function StressFaqSection() {
    const data =
    [
        {
          "title": "What are some simple and effective ways to reduce stress?",
          "body": "Stress reduction tools like mindfulness meditation, regular exercise, deep breathing, or yoga are highly effective. Even spending time outside or doing something creative can help relieve stress."
        },
        {
          "title": "How can I calm down quickly when I'm super stressed out?",
          "body": "If you need quick relief, try stress relieving tools such as a guided stress relief meditation, or calming music. These strategies can help you feel more calm in just a few minutes."
        },
        {
          "title": "What is mindfulness meditation, and why is it considered an effective stress management strategy?",
          "body": "Mindfulness meditation is an effective stress management strategy that helps you stay present and calm, lowers stress, and improves focus."
        },
        {
          "title": "How does working out help reduce stress?",
          "body": "Exercise is an effective way to manage stress because it releases endorphins, which boost your mood. It also serves as a great distraction from daily stressors."
        },
        {
          "title": "Can what I eat really make me more stressed?",
          "body": "Yes, your diet can impact stress levels. Eating well is an effective stress management strategy, while too much sugar or caffeine can increase anxiety."
        },
        {
          "title": "How does the Silva Method help with managing stress?",
          "body": "The Silva Method is a powerful stress reduction tool that uses meditation and visualization to calm your mind and help you feel more in control."
        },
        {
          "title": "Why is sleep so important for stress management?",
          "body": "Quality sleep is essential. It’s an effective stress management strategy that keeps you focused and better able to handle challenges."
        },
        {
          "title":"What are effective stress reduction techniques for students?",
          "body":"Students can manage stress through deep breathing, mindfulness meditation, exercise, staying organized, maintaining a to-do list, setting SMART goals, taking regular breaks, and adopting a healthy lifestyle."
        }
      ]
      
  return (
    <div className='container my-5'>
        <h2 className='heading-large text-black'>
            Frequently Asked Questions
        </h2>

        {
            data.map((val)=>(
                <FAQ2
                heading={val.title}
                body={val.body}
                />
            ))
        }

    </div>
  )
}

export default StressFaqSection