
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import StressManagementTechniques from './TechniquesSection'
import StressResources from './StressResources'
import ReactPlayer from 'react-player'
import StressRelieveAtHome from './StressRelieveAtHome'
import CardExamples from './StressCard1'
import NaturalWayToReduceStress from './NaturalWayToReduceStress'
import FormSection from '../LandingPages/FreeCourse/FormSection'
import Login from '../Login/Login'
import LongTermStressSolution from './LongTermStressSolution'
import StressRelieveExercise from './StressRelieveExercise'
import SilvaMethodSection from './SilvaMethodSection'
import DoctorConsultSection from './DoctorSection'
import StressFaqSection from './StressFaqSection'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import StressImpact from './StressEffect'
import StressImportant from './StressImportance'

function StressReductionHeroSection() {

    const { isMobile } = useContext(AuthContext);


    const understandingStress = [
        {
            icon: null,
            title: "Acute Stress",
            para: "Short-term stress that triggers the body's immediate response to a threat, like When you're running late or giving a presentation."
        },
        {
            icon: null,
            title: "Episodic Acute Stress",
            para: "This occurs when someone frequently experiences acute stress, like every time your boss calls or when you’re juggling too much."
        },
        {
            icon: null,
            title: "Chronic Stress",
            para: "The worst kind. This is ongoing stress from significant life factors, like financial struggles or relationship issues. It sticks around and can seriously harm your overall health. "
        }
    ]
    return (
        <>
            <div style={{
                marginTop:isMobile?"20px":"100px"
            }}>
                <div className='container'>
                    <section class="hero-stressed">

                        <div class="hero-stressed-content">
                            <h1 className='white-color mb-2 heading-medium text-start' style={{
                                fontSize:isMobile?"1.2rem":"auto"
                            }}>The Best Stress Management Techniques</h1>
                            <h3 className='white-color mt-0 mb-3 text-start' style={{
                                fontSize:isMobile?"1.2rem":"auto"
                            }}>
                                Everyone Needs to Know!
                            </h3>
                            {isMobile && (
                                 <div className="player-wrapper-bro mb-3">
                                 <ReactPlayer
                                     className="react-player-bro rounded-lg"
                                     url={"https://www.youtube.com/watch?v=GBasxgIbdLU"}
                                     width="100%"
                                     height={"100%"}
                                     controls={true}
                                     light={true}

                                     playing={false}
                                     
                                     config={{
                                         youtube: {
                                             playerVars: { showinfo: 1 }
                                         }
                                     }}
                                 />
                             </div>
                            )}
                            <p className='robotofont white-color' style={{
                                fontSize: "1rem"
                            }}>
                                Stress is unavoidable - Let’s face it.
                                Whether it’s the deadlines piling up, unexpected curveballs life throws, or even just juggling everyday tasks, stress finds its way into our lives. But here’s the thing:  while we can't completely escape stress, we can learn stress management techniques to handle it like a pro. Turn your stress into a source of strength; feel it as a motivator rather than a burden. Sounds impossible? Stick around because, in this vlog, we're diving deep into practical stress management techniques that will help you not just survive but thrive through stress.

                            </p>
                        </div>
                        {
                            !isMobile && (
                                <div class="hero-stressed-image">
                            {/* <img src="/img/stress/hp0edmxpevnilclcbv79.jpg" alt="Stress management visualization" /> */}
                            <div className="player-wrapper-bro">
                                <ReactPlayer
                                    className="react-player-bro rounded-lg"
                                    url={"https://www.youtube.com/watch?v=GBasxgIbdLU"}
                                    width="100%"
                                    height={"100%"}
                                    controls={true}
                                    light={false}
                                    playing={false}
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                            )
                        }
                    </section>


                    <section class="benefits-stressed">
                        <h2 class="text-center heading-large mb-2 text-black">What Is Stress</h2>
                        <h2 class="heading-medium mb-3 mt-0 stressed-section-title text-black"> Understanding The Science Behind It</h2>

                        <p className='robotofont text-center'>
                            Stress isn't just a psychological thing—it’s deeply rooted in biology. When you face a stressful event, the Amygdala (Notice the picture below)  —a region of the brain involved in processing emotions—sends a distress signal to the Hypothalamus. Acting as a command center, the Hypothalamus communicates with the rest of the body via the nervous system, preparing your body to switch into fight-or-flight mode. Think of it like your alarm system. At that time your nervous system responds by releasing stress hormones like Adrenaline and Cortisol. These stress hormones prepare you to either tackle the situation or escape it. Your heart races, breathing quickens, and muscles tense up—all designed to keep you safe in a real emergency.
                            Long-term exposure to stress can lead to burnout, anxiety, and even serious health consequences. The continuous release of stress hormones places a heavy strain on the cardiovascular system, heightening the risk of heart disease and stroke.
                            Understanding the science helps you see why stress management is so crucial. It’s not just about feeling calm—it’s about protecting your body from long-term damage.
                        </p>

                        <img src='/img/stress/Image20241113175544.jpg' className='my-3' style={{
                            borderRadius:"15px"
                        }} />


                    </section>

                    <section class="benefits-stressed">

                        <h2 class="heading-large mb-0 stressed-section-title text-black"> Types of Stress

                        <h4 className='text-black text-center mt-2'>
                        Know  Before You Learn stress reduction techniques


                        </h4>
                        </h2>
                      
                        <p className='robotofont'>
                            You may have often searched for ‘how to deal with stress,’ but before diving into stress management techniques, it's essential to understand the different types of stress. By recognizing your stress levels, you’ll be better equipped to choose the right stress reduction methods. So let’s find out -
                        </p>
                        <p className='robotofont'>
                            There are three types of stress:

                        </p>

                        <div class="benefits-stressed-grid">
                            {
                                understandingStress.map((val) => (
                                    <div class="benefit-stressed-card">
                                        <FaCheckCircle color='green' size={35} />
                                        <h3 className='lora-sans mb-2 mt-4'>{val.title}</h3>
                                        <p className='robotofont'>
                                            {val.para}
                                        </p>
                                    </div>
                                )
                                )
                            }


                        </div>

                    </section>


                </div>
                <StressImportant />

                <div className='container'>
                    <StressImpact />
                    <StressManagementTechniques />



                    <StressResources />

                    <StressRelieveAtHome />
                    <NaturalWayToReduceStress />
                    <div className='newsec'>
                    <FormSection />

                    </div>
                    <LongTermStressSolution />
                    <StressRelieveExercise />

                </div>

                {/* <CardExamples /> */}
            </div>
            <SilvaMethodSection />

            <div className='container'>
                <DoctorConsultSection />
                <StressFaqSection />

            </div>
        </>

    )
}

export default StressReductionHeroSection