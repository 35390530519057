import React from 'react'
import { Badge, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { useContext } from 'react'
import { useMemo } from 'react'
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import Tilt from 'react-parallax-tilt';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useState } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {phone} from 'phone';


function FormSection() {









    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);


    const { isMobile, isTablet } = useContext(AuthContext);

    const [checked, setChecked] = useState(true)


    const [values, setValues] = useState({
        email: "",
        whatsapp: "",
        firstname: "",
        lastname: "",
        courseid: "6646eada1aa02255519a2c2f",
       
    })

    const handleSubmit = async () => {

        if (!checked) {
            toast.error("You must subscribe to our newsletter to submit this form");
            return
        }


        if (!values.email || !values.firstname || !values.lastname || !values.whatsapp) {
            toast.error("Please enter all fields");
            return
        }

        const isValid = phone(values.whatsapp)

        if(isValid.isValid){
            submitVal()

        }else{
            toast.error("Please enter a valid phone number")
            return
        }




    }



    const submitVal = async () => {
        // console.log(values,"values")

        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            setLoading(true)
            const res = await axios.post("https://silvacasesonline.com:5000/api/v1/user/auth/enrollLead3"

                , values, {
                headers
            })

            setLoading(false)

            if (res && res.data.success) {

                toast.success("Form submitted successfully")
                navigate("/thank-you4")
            }else{
                toast.success(res.data.message)

            }

        } catch (err) {
            setLoading(false)

            if (err.response.data.message === "User already enrolled in this list") {

                toast.error("You have already registered for this class.")
            }
            return

        }
    }




    return (
        <>
            <div className='container formsection' style={{
                paddingTop: isMobile ? 30 : 80,
                paddingBottom: isMobile ? 50 : 100
            }}>
                <Row className='container justify-content-start align-items-center'>
                    <Col xs={12} lg={6}>
                        {/* <Tilt style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }} tiltEnable={true} glareMaxOpacity={0.2} glareColor="#000" glarePosition="all"
                            glareBorderRadius="20px"
                            reset={true}
                        >
                            <img style={{
                                width: (isTablet || isMobile) ? "100%" : "90%",
                                borderRadius: 15,
                                marginBottom: isMobile ? "20px" : "0px"
                            }}
                                src='/img/Meditation Banner.webp'
                            />

                        </Tilt> */}
                        <video src='https://silva-method-official.b-cdn.net/edited_square.mp4' autoPlay controls style={{
                            width:isMobile?"100%":"90%",
                            height:"100%",
                            borderRadius:"15px",
                            marginBottom:isMobile?"20px":"0px"
                        }}
                        poster='/img/Image20241204183016.png'
                        />

                    </Col>
                    <Col xs={12} lg={6}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexDirection: "column"
                        }}>
                            <HeadingNew title={" Register to Get Started"} start />
                            <p className='robotofont mt-2'>
                            Stay updated with daily reminders and class details!
                            </p>


                        </div>

                        <div className='mt-3'>
                            <Row className='gy-3' style={{
                                paddingLeft: "15px"
                            }}>
                                <Col xs={12} md={6}>
                                    <label>Enter your first name</label>
                                    <input placeholder="Enter your first name" class="input-style" type="text" style={{
                                        width: isMobile ? "100%" : "95%"
                                    }}
                                        value={values.firstname}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                firstname: e.target.value
                                            })
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <label>Enter your last name</label>
                                    <input placeholder="Enter your last name" class="input-style" type="text" style={{
                                        width: isMobile ? "100%" : "95%"
                                    }}
                                        value={values.lastname}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                lastname: e.target.value
                                            })
                                        }}
                                    />

                                </Col>
                                <Col xs={12} md={6}>
                                    <label>Enter your email</label>
                                    <input placeholder="Enter your email" class="input-style" type="text" style={{
                                        width: isMobile ? "100%" : "95%"
                                    }}
                                        value={values.email}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                email: e.target.value
                                            })
                                        }}
                                    />

                                </Col>
                                <Col xs={12} md={6}>
                                    <label>Enter your whatsapp/phone number with country</label>
                                    <PhoneInput
                                        inputStyle={{
                                            height: "45px",
                                            borderTop: "2px solid #a8a1a1",
                                            borderBottom: "2px solid #a8a1a1",
                                            borderRight: "2px solid #a8a1a1",

                                        }}
                                        className='reactintcountry'
                                        style={{
                                            height: "45px",
                                            color: "#a8a1a1",
                                        }}
                                        countrySelectorStyleProps={{
                                            height: "45px",
                                        }}
                                        placeholder='Enter country code and whatsapp number'
                                        defaultCountry="usa"
                                        onChange={(ph, country) => {
                                            setValues({
                                                ...values,
                                                whatsapp: ph,
                                            })
                                        }}

                                    />


                                </Col>

                            </Row>
                            <div class="checkbox-wrapper-10 mt-3">
                                <input id="example-10" class="substituted" type="checkbox" aria-hidden="true" checked={checked} onChange={(e) => {
                                    setChecked(e.target.checked)
                                }} />
                                <label for="example-10" style={{
                                    margin: "0px",
                                    padding: "0px"
                                }}>
                                    Subscribe to the Silva Method Newsletter to receive inspiring content and practical tips to enhance your meditation journey.
                                </label>
                            </div>
                            <button className='btnnew22 mt-4' onClick={handleSubmit} disabled={loading}>
                                <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                        fill="black"
                                    ></path>
                                </svg>
                                <span>
                                    {
                                        loading ? "...loading" : "Enroll For Free"
                                    }
                                </span>
                            </button>
                        </div>

                    </Col>

                </Row>



            </div>

        </>
    )
}

export default FormSection