import React, { useEffect, useRef } from 'react';
import HeadingNew from '../AboutUs/HeadingNew';

const NewAddOn = () => {
  const cardRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slv-fade__active');
          }
        });
      },
      { threshold: 0.1 }
    );

    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => observer.disconnect();
  }, []);

  const regions = [
    {
      title: 'Silva Method UK',
      content: "Join a thriving community of like-minded individuals across the UK, with access to local seminars, events, and online courses. Whether you're in London, Manchester, or beyond, discover how the Silva Method can improve your potential."
    },
    {
      title: 'Silva Method USA',
      content: "From the East Coast to the West Coast, the Silva Method USA offers a variety of in-person and online opportunities to dive into dynamic meditation, alpha state relaxation, and other transformative practices. Connect with instructors and students nationwide, including areas like Texas, California, and New York."
    },
    {
      title: 'Silva Method Canada',
      content: "Whether you're in Toronto, Vancouver, or Montreal, the Silva Method Canada community is growing. With dedicated workshops, courses, and support networks, Canadians are embracing this powerful method to reduce stress, boost intuition, and foster personal and professional success."
    }
  ];

  return (
    <div className="container">
      <style>
        {`
          .slv-container {
            padding: 2rem;
            max-width: 1200px;
            margin: 0 auto;
            font-family: 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
            background: #f5f5f5;
          }

          @keyframes slv-gradient {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }



          @keyframes slv-glow {
            0% { box-shadow: 0 0 5px rgba(110, 72, 170, 0.2); }
            50% { box-shadow: 0 0 20px rgba(110, 72, 170, 0.4); }
            100% { box-shadow: 0 0 5px rgba(110, 72, 170, 0.2); }
          }

          @keyframes slv-fadeIn {
            from { opacity: 0; transform: translateY(30px) scale(0.9); }
            to { opacity: 1; transform: translateY(0) scale(1); }
          }

          .slv-header {
            background-size: 200% 200%;
            animation: slv-gradient 15s ease infinite;
            padding: 3rem 2rem 1rem 2rem;
            border-radius: 20px;
            color: white;
            text-align: center;
            position: relative;
            overflow: hidden;
          }

          .slv-header::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
          }

          .slv-header__title {
            font-size: 2.8rem;
            margin-bottom: 1.5rem;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
          }

          .slv-header__desc {
            font-size: 1.2rem;
            max-width: 800px;
            margin: 0 auto;
            line-height: 1.6;
          }

          .slv-cards {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
            gap: 2.5rem;
            opacity: 0;
          }

          .slv-fade__active {
            opacity: 1;
            animation: slv-fadeIn 1s ease-out forwards;
          }

          .slv-card {
            position: relative;
            background: linear-gradient(-45deg, #ff3366, #ba3f95, #6e48aa);
            background-size: 200% 200%;
            animation: slv-gradient 15s ease infinite, slv-floating 6s ease-in-out infinite;
            border-radius: 20px;
            padding: 3px;
            transform-style: preserve-3d;
            perspective: 1000px;
          }

          .slv-card:hover .slv-card__inner {
            transform: translateZ(20px);
          }

          .slv-card:hover::before {
            opacity: 1;
          }

          .slv-card::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            background: linear-gradient(-45deg, #ff3366, #ba3f95, #6e48aa);
            background-size: 200% 200%;
            animation: slv-gradient 15s ease infinite;
            border-radius: 22px;
            z-index: -1;
            opacity: 0;
            transition: opacity 0.3s ease;
            filter: blur(15px);
          }

          .slv-card__inner {
            background: rgba(255, 255, 255, 0.9);
            backdrop-filter: blur(10px);
            padding: 2rem;
            border-radius: 18px;
            height: 100%;
            transition: transform 0.3s ease;
          }

          .slv-card__title {
            color: #6e48aa;
            margin-bottom: 1.5rem;
            font-size: 1.8rem;
            position: relative;
            padding-bottom: 0.5rem;
          }

          .slv-card__title::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60px;
            height: 3px;
            background: linear-gradient(90deg, #ff3366, #6e48aa);
            border-radius: 3px;
          }

          .slv-card__content {
            color: #444;
            line-height: 1.8;
            font-size: 1.1rem;
          }

          @media (max-width: 768px) {
            .slv-container {
              padding: 1rem;
            }

            .slv-header {
              padding: 2rem 1rem;
              margin-bottom: 2rem;
            }

            .slv-header__title {
              font-size: 2rem;
            }

            .slv-cards {
              grid-template-columns: 1fr;
              gap: 2rem;
            }

            .slv-card__title {
              font-size: 1.5rem;
            }
          }
        `}
      </style>

      <div className="slv-header">
      <HeadingNew title={"Explore the Silva Method Worldwide"} white={false} />
      
        <p className="robotofont mt-2">
          Our Silva Method Online community spans across these regions, providing local courses, 
          workshops, and resources to help you master Silva Method techniques and achieve your 
          personal growth goals.
        </p>
      </div>

      <div className="slv-cards" ref={el => cardRefs.current[0] = el}>
        {regions.map((region, index) => (
          <div key={region.title} className="slv-card">
            <div className="slv-card__inner">
              <h2 className="slv-card__title">{region.title}</h2>
              <p className="slv-card__content robotofont">{region.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewAddOn;