import React from 'react'
import { useContext } from 'react'
import { Badge, Col, Row } from 'react-bootstrap'
import { TypeAnimation } from 'react-type-animation'
import { AuthContext } from '../../../context/AllContext'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'
import ReactStars from 'react-stars'
import { FaStar } from "react-icons/fa";
import Tilt from 'react-parallax-tilt';
import { useLocation, useNavigate } from 'react-router-dom'
import ReactHlsPlayer from 'react-hls-player'

function HeroSectionEnt() {
    const location = useLocation()
    const { isMobile, isTablet, isMediumDesktop } = useContext(AuthContext);

    const navigation = useNavigate()

    const handleScrollCombo2Curr2 = () => {
        if (location.pathname === "/store/course/combo-plan") {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('token', 'NEWYEAR60');
            window.location.search = urlParams;
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('token', 'NEWYEAR60');
            navigation('/how-to-manifest?token=NEWYEAR60')
            handleclick()

        }
    }

    const handleclick = () => {


        polyfill()

        const doc = document.querySelector(".herosectionslide")
        setTimeout(() => {

            scrollIntoView(doc, {
                behavior: "smooth",
                block: "end", inline: "nearest"
            });
        }, 300)


    }
    return (
        <div className='container'>
            <Row className={isMobile ? 'justify-content-start align-items-center px-3' : 'justify-content-center align-items-center px-2'}>
                <Col xs={12} xl={5}>
                    {/* <Badge pill bg="warning" style={{
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        color: "black"

                    }}>
                        Special Offer
                    </Badge> */}


                    <h1 className='mt-3' style={{
                        fontSize: isMobile ? "2rem" : "3rem",
                        color: "white",
                        fontWeight: "700",
                        textAlign: isMobile ? "start" : "start"
                    }}>
                        How To Manifest

                        <span
                            className='mx-2'
                            style={{ color: "#ffc107", lineHeight: "1.1rem", fontSize: isMobile ? "1.8rem" : "3rem", display: "inline", fontWeight: "700", marginRight: isMobile ? "10px" : "0px" }}
                        >
                            10X

                        </span>


                        Faster With
                        <span style={{
                            position: 'relative',
                            whiteSpace: 'nowrap',
                            color: '#fff'
                        }}>
                            <svg aria-hidden="true" viewBox="0 0 418 42" style={{
                                position: 'absolute',
                                top: '66.67%',
                                left: 0,
                                height: '0.58em',
                                width: '100%',
                                fill: '#fff'
                            }} preserveAspectRatio="none">
                                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                            </svg>
                            <span style={{ position: 'relative', marginLeft: "10px" }}>Silva Method</span>
                        </span>





                    </h1>

                    <div style={{
                        maxWidth: isMobile ? "100%" : "100%"
                    }}>

                        <p className='text-start mt-3 mb-3' style={{
                            fontSize: "1rem",
                            color: "white",
                            margin: "0px",
                            padding: "0px",
                            fontWeight: "400"
                        }}>

                            A super hyped word on the internet. Everyone is talking about the power of manifestation. Do you also want to explore this? discover the Silva Method – a premier platform in mind development.



                        </p>


                    </div>

                    {
                        (isMobile || isTablet || isMediumDesktop) && (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} className='my-4'>

                                <ReactHlsPlayer src='https://vz-2ffc5351-a02.b-cdn.net/acaeb396-773e-4727-9397-bcb9d178a45d/playlist.m3u8' controls style={{
                                    width: "100%",
                                    height: "100%"
                                }} />
                            </div>
                        )
                    }
                    <div class="d-grid gap-2">
                        <button className='btn btn-lg' style={{
                            borderRadius: "15px",
                            height: "3.8rem",
                            fontSize: "1.3rem",
                            background: "linear-gradient(to right, #7F00FF, #E100FF)",
                            border: "none",
                            color: "white",
                            fontWeight: "500"
                        }}
                            onClick={handleScrollCombo2Curr2}
                        >
                            Start Your Journey Today

                        </button>
                    </div>

                    <div className='d-flex gap-4 justify-content-start align-items-center my-4 my-md-4 flex-wrap' style={{
                        width: "fit-content",
                        marginLeft: isMobile ? "20px" : "0px"
                    }}>
                        <div className='d-flex justify-content-center align-items-center gap-3'>
                            <div class="avatars">
                                {
                                    isMobile ? (
                                        <>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>

                                        </>
                                    ) : (
                                        <>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                                            <a onClick={handleScrollCombo2Curr2} style={{
                                                cursor: "pointer"
                                            }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>


                                        </>
                                    )
                                }


                            </div>
                            <h6 style={{
                                color: "white",
                                marginTop: "10px"
                            }}>
                                Join 12M+
                                <br />
                                Active Students
                            </h6>
                        </div>


                        <div className='d-flex flex-column'>
                            <h3 className='white-color fw-700 d-flex align-items-center gap-2'>
                                4.8+ <span className='white-color fw-500' style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "500"
                                }}>(29780 Ratings)</span>
                                <span>
                                    <ReactStars value={4.8} edit={false} size={"30px"} count={5} />
                                </span>
                            </h3>



                        </div>
                    </div>


                </Col>
                {
                    (!isMobile && !isTablet && !isMediumDesktop) && (
                        <Col xs={12} md={7}>

                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} className='mt-3'>


                                {/* <img src='/img/manifest 2.webp' style={{
                                    width: "90%",
                                    height: "100%",
                                    borderRadius: 15
                                }} /> */}
                                <ReactHlsPlayer src='https://vz-2ffc5351-a02.b-cdn.net/acaeb396-773e-4727-9397-bcb9d178a45d/playlist.m3u8' controls style={{
                                    width: "90%",
                                    height: "90%"
                                }} />
                            </div>

                        </Col>
                    )
                }

            </Row>
        </div>
    )
}

export default HeroSectionEnt