import React, { useContext, useState } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomFooter from '../Home/Home/HomeFooter/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import { AuthContext } from '../../context/AllContext';
import useWindowSize from 'react-use/lib/useWindowSize'
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import { FaYoutube, FaWhatsapp } from "react-icons/fa";

import Confetti from 'react-confetti'
import HeadingNew from '../AboutUs/HeadingNew';
import CardsMeditate from './CardsThankyou';
import ScrollIndicator from './ScrollIndicator';
import { useEffect } from 'react';

const ThankYou4 = () => {
    const { isMobile } = useContext(AuthContext)
    const { width, height } = useWindowSize()
    const [selectedVideo, setSelectedVideo] = useState(null);

    
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, [])

    return (
        <>
            <div>
                <div
                    style={{
                        background: `#510286`,
                        border: '1px solid black',
                        padding: '50px 50px',
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >

                    <div className='d-flex justify-content-center align-items-center flex-column mb-4'>
                        <Image src='img/thankyou/thankyou.gif' height={isMobile ? "auto" : 500} style={{ borderRadius: '10px' }} />
                        <div className='d-flex justify-content-center align-items-center  mb-3'>
                       
                    </div>
                        <p className='robotofont text-white'>
                            We'll send you an email soon with all the class details in your registered email id

                        </p>
                        <p className='robotofont text-white'>
                            <i>
                                If you don't see it in your inbox , please check your SPAM folder also. Contact us anytime at <a className='white-color' href='mailto:digital@silvamethod.com'>digital@silvamethod.com</a>
                            </i>
                        </p>
                    </div>
                   
                    <HeadingNew
                        title={" Stay Connected With Us"}
                        small
                        white
                    />


                    <p className='robotofont text-white'>
                        Join our whatsapp group now to get daily updates and reminders.

                    </p>
                    <div className='row justify-content-center align-items-center'>

                        <button

                            className='col-md-4 button-87'
                            style={{
                                width: "150px"
                            }}
                            onClick={() => {

                                const whatsappGroupURL = 'https://chat.whatsapp.com/IVIJBZ9FRCS4wYoe8KWe73';

                                window.open(whatsappGroupURL);
                            }}
                        >
                            <span><FaWhatsapp size={30} color='white' /></span>Join Group
                        </button>


                    </div>

                    <ScrollIndicator />

                </div>
            </div>

            {
                !selectedVideo && (
                    <Confetti
                        width={width}
                        gravity={0.2}
                        initialVelocityX={10}
                        initialVelocityY={10}
                        height={height}
                    />
                )
            }
            <CardsMeditate selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />
        </>
    );
};

export default ThankYou4;
