import React, { useContext } from 'react'
import { AuthContext } from '../../context/AllContext';
import { Card, Image } from 'react-bootstrap';
import ParaComp from '../../pages/SilvaManifestationProgram/ParaComp';
import HeadingNew from '../../pages/AboutUs/HeadingNew';

function CustomerReviews({ data }) {
     
    const { isDesktopOrLaptop,isBigScreen,isTablet,isMobile} = useContext(AuthContext);
    

    return (
    
        <Card style={{width:"96%",borderRadius:"15px",height:isMobile?"auto":"auto",marginLeft:isMobile?"5px":"0",padding:"20px"}} className='mt-3'>
            <Card.Body>
                <div className='cardreviewcont'>
                    <div className='imgdiv'>
                        <Image src={data.image} style={{width:isMobile?"160px":"300px",height:isMobile?"160px":"250px",
                        objectFit:"cover",borderRadius:"15px"}} />
                    </div>
                    <div className='customreviewscard'>

                      <ParaComp data={[data.comment]} white={false} />
                      <div className='mt-4'>
                        <HeadingNew title={data.name} small start={true} white={false} />
                        <ParaComp
                        data={[
                            data.designation
                        ]}
                        white={false}
                        margin={false}
                        />
                      </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default CustomerReviews