import React from 'react';
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import { FaCheckCircle } from 'react-icons/fa';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import TimeZoneBadge from './TimezoneBadge';
import WeekdayButton from './WeekdaysButton';


const NewVideoSection = () => {

    const { isMobile } = useContext(AuthContext)

    const handleScroll = () => {
        polyfill();
        const cardscroll = document.querySelector(".newsec");

        if (cardscroll) {
            //  cardscroll.scrollIntoView({
            //      behavior: "smooth"
            //  })
            setTimeout(() => {
                scrollIntoView(cardscroll, {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                });
            }, 300);
        }
    };

    
    const handleScroll2 = () => {
        polyfill();
        const cardscroll = document.querySelector(".formsection");

        if (cardscroll) {
            //  cardscroll.scrollIntoView({
            //      behavior: "smooth"
            //  })
            setTimeout(() => {
                scrollIntoView(cardscroll, {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                });
            }, 300);
        }
    };
    return (
        <div className="med-wrapper">
            <style>
                {`
          .med-wrapper {
            height: auto;
            width: 100%;
            background-image: url('/img/Image20241119162752.png');  /* Replace with your background image */
            background-size: contain;
            background-position: center;
            position: relative;
            overflow: hidden;
            display: flex;
          }

          .med-container {
            width: 100%;
            display: flex;
            position: relative;
          }

          .med-left {
            width: 60%;
            background: linear-gradient(337deg, #160336 0%, #3a0049 49%);
            margin-top:3rem;
            margin-bottom:3rem;
            border-radius: 0 2rem 2rem 0;
            padding: 4rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .med-right {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem;
          }

          .med-content {
            color: white;
          }

          .med-title {
            font-size: clamp(2.5rem, 5vw, 4rem);
            margin-bottom: 1rem;
            font-weight: bold;
            text-transform: uppercase;
          }

          .med-subtitle {
            font-size: clamp(1.5rem, 3vw, 2.5rem);
            text-transform: uppercase;
          }

          .med-image {
            width: 100%;
            height: auto;
            max-width: 600px;
            object-fit: contain;
          }

        //   @media (max-width: 768px) {
        //     .med-container {
        //       flex-direction: column;
        //     }

        //     .med-left{
        //        margin-top:0rem;
        //        margin-bottom:0rem;
        //     }
        //     .med-left, .med-right {
        //       width: 100%;
        //     }

        //     .med-left {
        //       border-radius: 0 0 2rem 2rem;
        //       padding: 2rem;
        //     }

        //     .med-right {
        //       padding: 2rem;
        //     }
        //   }
        @media (max-width: 768px) {
  .med-container {
    flex-direction: column;
  }

  .med-left {
    margin-top: 0rem;
    margin-bottom: 0rem;
    order: 2; /* Set med-left to appear second */
    width: 100%;
    border-radius: 0 0 2rem 2rem;
    padding: 2rem;
  }

  .med-right {
    order: 1; /* Set med-right to appear first */
    width: 100%;
    padding: 2rem;
  }
}
        `}
            </style>

            <div className="med-container my-5">
                <div className="med-left">
                    <div className="med-content">


                        {/* <HeadingNew small title={"Your Daily Dose of Meditation"} white start /> */}

                        <h2 className='dancing-script2' style={{
                            fontSize: "4rem",
                            color: "#fff"
                        }}>
                          Your 


                        </h2>

                       <div className='d-flex justify-content-start align-items-center gap-3'>
                       <p className='display-4 fw-bold mb-0' style={{
                            color:"#feff00"
                        }}>
                        PRICELESS 

                        </p>
                        <h5 className='fw-bold mt-3 white-color' style={{
                            fontSize:"1.5rem"
                        }}>
                            TICKET TO
                        </h5>   

                       </div>

                        <h4 className='display-4  fw-bold text-white'>
                        TRANSFORMATION
                        </h4>

                        <p className='robotofont mt-0 text-white mb-1'>
                        Silva Method Daily Free Meditation Session

                        </p>

                        <p className='robotofont mt-0 text-white'>
                        Feel more focused, stress-free, and ready to take on anything life throws your way!
                        </p>
                        <h5 className='fw-bold mt-3 white-color' style={{
                            fontSize:"1.5rem"
                        }}>
                        Relax, Recharge, Thrive!
                        </h5>   


                        <div className="d-flex flex-column gap-3 mt-4">
                            <div className="d-flex gap-2 flex-wrap">
                                <span>
                                    <FaCheckCircle color="#fff" size={25} />
                                </span>
                                <p
                                    style={{
                                        margin: "0px",
                                        padding: "0px",
                                        width: isMobile ? "80%" : "auto",
                                        color: "white",
                                        fontSize: isMobile ? "0.85rem" : "1rem",
                                        lineHeight: isMobile ? "20px" : "auto",
                                    }}
                                >
                                   Join with Alex ( AKA Shyamal Roy)

                                </p>
                            </div>
                            <div className="d-flex gap-2 flex-wrap">
                                <span>
                                    <FaCheckCircle color="#fff" size={25} />
                                </span>
                                <p
                                    style={{
                                        margin: "0px",
                                        padding: "0px",
                                        width: isMobile ? "80%" : "auto",
                                        color: "white",
                                        fontSize: isMobile ? "0.85rem" : "1rem",
                                        lineHeight: isMobile ? "20px" : "auto",
                                    }}
                                >
                                    Monday - Friday

                                </p>
                            </div>
                           
                        </div>
                        <TimeZoneBadge />
                        <div className={isMobile?'d-flex justify-content-start  align-items-center gap-1 flex-wrap':'d-flex justify-content-start  align-items-center gap-3 flex-wrap'}>
                            <button className="btnnew22 mt-4" onClick={() => {
                                handleScroll2()
                            }}

                            >
                                <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                        fill="black"
                                    ></path>
                                </svg>
                                <span>Register For Free</span>
                            </button>

                            <WeekdayButton />


                        </div>

                    </div>
                </div>

                <div className="med-right">
                    <img
                        src="/img/Image20241119163009.png"
                        // src='/freeclassnew/Artboard 3.png'
                        alt="Meditation visual"
                        className="med-image"
                    />
                </div>
            </div>
        </div>
    );
};

export default NewVideoSection;