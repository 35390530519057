import React, { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import { BlackFridayTimer, ProductGrid } from './CardAndTimer';
import { StatisticsDisplay } from './StatsDisplay';
import BlackFridayComboSection from '../../CoursePage2/BlackFridayHeroSection';
import AddOn2 from '../ComboCourse2/BonusSection2';
import ComboTestimonials2 from './ComboTestimonials2';
import AboutSilvaMethod2 from '../../CoursePage2/AboutSilvaMethod2';
import MobileSection2 from '../../CoursePage2/MobileSection2';
import HeadingNew from '../../AboutUs/HeadingNew';

function BlackFridaySpecialOffers({scrollTo}) {
  const {isMobile} = useContext(AuthContext);

  return (

    <>
      <div className='container my-5'>
        {/* <h2 className='heading-large text-black blackfriday-title'></h2> */}
        <HeadingNew
        title={"BLACK FRIDAY SPECIAL OFFERS"}
        />
      </div>
<BlackFridayTimer />

      <section className="">
        <div className="ps_container ps_container2">
          <div className="ps_image_col">
            <div className="ps_image_wrapper">
              <img
                src="/img/blackfriday/all-in-one-pack.webp"
                alt="Premium Headphones"
                className="ps_product_image"
              />
            </div>
          </div>
          <div className={isMobile?"ps_content_col container mb-5":"ps_content_col"}>
            <div className="ps_discount ">60% OFF </div>
            <div className='mb-2'>
            <HeadingNew title={"Silva Method Complete Program"} start />
            </div>
            {/* <h2 className="ps_title text-black mb-2"></h2> */}
            <HeadingNew title={"The All in One Pack"} small start />
            {/* <h3 className={isMobile?'heading-medium text-black text-start mb-3':'heading-medium text-black text-start mb-3'}>

            </h3> */}
            <ul className="ps_features2 mt-2">
              <li className='text-black'>All Silva Method Digital Courses Included</li>
              <li className='text-black'>Exclusive Live Interactive Sessions</li>
              <li className='text-black'>Real- Time Guidance from Instructors</li>
              <li className='text-black'>Lifetime Access</li>

            </ul>
            <button className="bfs_cta" onClick={scrollTo}>Join Now</button>
          </div>
        </div>
      </section>


      {/* <div className="pb_wrapper ">
        <div className="pb_container">
          <div className="pb_content">
            <div className="pb_offer">
              <span className="pb_highlight ">
                Up to 60% OFF
              </span>
              <span className="pb_terms">Catch the Calm
                at an
                Unbeatable Price!</span>
            </div>
            <button className="pb_cta">Grab Now </button>
          </div>
        </div>
      </div> */}


      {/* Main Offer Section */}
      <section className="ps_wrapper">
        <div className="ps_container ps_container2">
          {/* Content Column */}
          <div className="ps_content_col">
            <div className="ps_discount" style={{
              lineHeight: "3rem"
            }}>Exclusive Live Interactive Classes</div>
            <h1 className="ps_title white-color">Learn Silva Method Like Never Before <br />at $0 Extra Cost</h1>
            <ul className="ps_features">
              <li className="white-color">Lifetime Access</li>
              <li className="white-color">Trusted by 1M+ Silva Enthusiasts</li>
              <li className="white-color">Weekly Sessions Every Saturday</li>
              <li className="white-color">Real-Time Support from Instructors</li>
              <li className="white-color">Join our Thriving Whatsapp Community</li>


            </ul>
            <button className="bfs_cta" onClick={scrollTo}>Join Now</button>
          </div>



          {/* Image Column */}
          <div className="ps_image_col">
            <div className="ps_image_wrapper">
              <img
                src="/img/blackfriday/Live class.webp"
                alt="Premium Headphones"
                className="ps_product_image"
              />
            </div>
          </div>
        </div>
      </section>
      <ProductGrid scrollTo={scrollTo} />
      <StatisticsDisplay />
      {/* <BecomeMemberSection2 /> */}
      <AddOn2 />

      <BlackFridayComboSection />
      <ComboTestimonials2 />
      <AboutSilvaMethod2 />
      <div className='mb-5'>
      <MobileSection2 />
      </div>

      
      
      

    </>
  )
}

export default BlackFridaySpecialOffers