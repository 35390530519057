import React from 'react';
import { GlowingBadge } from './BlackFridayHero2';

const HighlightedPromo = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      fontFamily: 'system-ui, sans-serif',
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },
    mainHighlight: {
      display: 'inline-block',
    //   background: 'linear-gradient(120deg, rgba(255, 153, 0, 0.15) 0%, rgba(255, 153, 0, 0.15) 100%)',
     color: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      padding: '4px 8px',
      fontWeight: '600',
      marginTop:"15px",
      fontSize: '1.5rem',
      textAlign:"center",
      borderRadius: '4px',
      textShadow: '0 0 10px rgba(255, 183, 77, 0.3)',
      position: 'relative',
    },
    priceHighlight: {
      display: 'inline-block',
      background: 'linear-gradient(0deg,#f5d222 0%,#ffd814 100%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      padding: '2px 6px',
      textAlign:"center",

      color: '#000',
      fontWeight: '600',
      borderRadius: '4px',
      textShadow: '0 0 8px rgba(129, 199, 132, 0.3)',
    },
    description: {
      fontSize: '1.25rem',
      lineHeight: '1.6',
      marginBottom: '1rem',
      color: '#fff',
      textAlign:"center",

    },
    offer: {
      color: '#fff',
      fontSize: '1.2rem',
      fontStyle: 'italic',
      textShadow: '0 0 8px rgba(255, 138, 101, 0.3)',
      textAlign:"center",

    },
    strikethrough: {
      textDecoration: 'line-through',
      color: '#fff',
    },
    pulse: {
      animation: 'pulse 2s infinite',
    },
    '@keyframes pulse': {
      '0%': {
        boxShadow: '0 0 0 0 rgba(255, 183, 77, 0.4)',
      },
      '70%': {
        boxShadow: '0 0 0 10px rgba(255, 183, 77, 0)',
      },
      '100%': {
        boxShadow: '0 0 0 0 rgba(255, 183, 77, 0)',
      },
    }
  };

  return (
    <div style={styles.container}>
      <style>
        {`
          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 rgba(255, 183, 77, 0.4);
            }
            70% {
              box-shadow: 0 0 0 10px rgba(255, 183, 77, 0);
            }
            100% {
              box-shadow: 0 0 0 0 rgba(255, 183, 77, 0);
            }
          }
        `}
      </style>
      
      <h1 style={{...styles.mainHighlight}}>
        Flat 60% Off on The Complete Silva Method Program
      </h1>
      
      <p style={styles.description}>
        Get Lifetime Access to <br /> <span className='mt-2' style={styles.priceHighlight}>8+ Original Silva Method Courses </span>  with {" "}
        <span className='mb-2' style={styles.priceHighlight}> Free Live Interactive Classes</span> <br /> Just at {" "}
        <span style={styles.priceHighlight}> $297</span> 
        <span style={styles.strikethrough}> ($750)</span>
      </p>
      
      <p style={{...styles.offer, ...styles.pulse}}>
        *Offer valid until 30th November.*
      </p>
      <div className='d-flex justify-content-center align-items-center'>
      <GlowingBadge />
      </div>

    </div>
  );
};

export default HighlightedPromo;