import React, { useState } from 'react';
import './SilvaBanner.css';
import { Link } from 'react-router-dom';

const SilvaBanner = () => {

  // Replace with actual image paths when available


  return (
    <div className='container'>
        <div className='x-wrapper'>
    <div className="q-container">
   <div className="z-content">
     <div className="j-text-block">
       <div className="k-tag">
         <span className="l-highlight">New Course Launched!</span>
       </div>
       <h1 className="m-title">Silva Life & Intuition Combo Course</h1>
       <p className="n-description robotofont">
       Get Full Access to 100% Original Silva Method Mind Control Courses. Explore the combined power of Silva Life System and Silva Intuition System, two foundational courses of the Silva Method. Gain exclusive access to live interactive online classes conducted by certified Silva Method instructors, providing personalized guidance and real-time learning at no additional cost.
       </p>
       <div className="p-pricing">
         <div className="r-original-price robotofont fw-bold">$440</div>
         <div className="s-discounted-block">
           <span className="t-price">$176</span>
           <span className="u-discount-tag">60% OFF</span>
         </div>
       </div>
       <ul className="v-features robotofont">
         <li>Master your mind</li>
         <li>Enhance intuition</li>
         <li>Make better decisions effortlessly</li>
         <li>Live interactive sessions</li>
         <li>Holistic personal growth</li>
       </ul>
       <Link className="w-cta-button" to={"/store/course/silva-method-combo-course"}>Enroll Now</Link>
     </div>
     
     <div className="h-image-container">
       <img 
         src={"/img/newcourseimage-3copy_1.jpg"} 
         alt="Silva Life & Intuition Combo Course"
         className="g-image"
       />
     </div>
   </div>
 </div>
  </div>
    </div>
  );
};

export default SilvaBanner;