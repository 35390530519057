import React from 'react';

import styles from "./Mindful.module.css"

const MindFulnessHeroSection = ({scrollTo}) => {

    return (
        <div className={styles.heroContainer2}>
            <div className={styles.heroOverlay}></div>
            <div className={styles.heroContent}>
                <div className={styles.heroLeft}>
                    <div className={styles.textContent}>

                        <h1 className={styles.heading}>
                         Mindfulness Meditation
                     
                        </h1>

                      
                        <h3 className={styles.subheading}>
                        A mindfulness guide 
                        </h3>

                      

                    </div>
                </div>

                <div className={styles.heroRight}>
                    <div className={styles.videoContainer}>
                        <div>
                            <img
                                src={"/img/bg/blogpages/blogpagemindful/header-img.webp"}
                                style={{
                                    border:"2px solid white",
                                    borderRadius:"15px"
                                }}

                                className={styles.video}
                                // onClick={scrollTo}
                            />
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MindFulnessHeroSection;