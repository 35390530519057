import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../../../context/AllContext";
import { useNavigate } from "react-router-dom";

function BenifitSectionHyp({btnlink=false}) {

  const {isMobile,isBigScreen}= useContext(AuthContext);

  const benifits = [
    {
      id: 1,
      text: "8 original courses.",
    },
    {
      id: 2,
      text: "280+ lessons.",
    },
    {
      id: 3,
      text: "85+ exercises.",
    },
    {
      id: 4,
      text: "3+ live classes every month.",
    },
    {
      id: 5,
      text: "Free doubt-clearing sessions.",
    },
  ];

  const navigate= useNavigate();

  const handleScroll = ()=>{
    if(btnlink){
      navigate("/store/course/combo-plan")
    }
    const card = document.querySelector(".cardtoscrollcombo")
    if(card){
        card.scrollIntoView({
            behavior:"smooth"
        })
    }
  }
  return (
    <div className="benifits_image_bg mt-3" style={{ padding: isMobile?"20px 0px": "80px 0px" }}>

      <div className="container">
        <Row className="d-flex justify-content-center align-items-center">
          <Col sm={12} md={12} lg={6}>
            <div className={`px-${isMobile?"2":"0"}`}>
            <img
              src="/img/hypnosis/benifits_image.png"
              alt=""
              style={{ width:(isBigScreen)? "80%":"100%", height:(isBigScreen)? "80%":"100%" }}
            />
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} className={"px-2"}>
            <div className="container">
              <h4 className="text-white mt-3">
                The Silva Method Newly Launched Curriculum
              </h4>
              <b className="text-white mt-3">
                Silva Method Complete Course + Live Interactive Classes
              </b>
              <p className="text-white my-3">
                Get lifetime access to all 100% original Silva Method Courses
                with Live interactive sessions from the experts. Enroll now for
                an enriching journey toward inner harmony and fulfillment.
              </p>
              <p className="text-white my-3">Additionally, enjoy these perks:</p>
            </div>
            <div className="my-3" style={{
                marginLeft:20
            }}>
              {benifits.map((benifit, i) => (
                <div style={{
                    height:30
                }}>
                    <div className="d-flex align-items-center gap-2">
                  <IoMdCheckmarkCircle color="white" size={20} />
                  <p className="text-start text-white" style={{
                    margin:0,
                    padding:0
                  }} key={i}>
                    {benifit.text}
                  </p>
                </div>
                </div>
              ))}
            </div>
            <button
              className={`p-3 px-${isMobile?4:5} border border-0 rounded-3 fw-bold mx-3`}
              style={{ backgroundColor: "#ffd401" }}
              onClick={handleScroll}
            >
              Get Access Now
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default BenifitSectionHyp;
