import React from 'react';
import './SilvaCourseDescription.css';
import HeadingNew from '../../AboutUs/HeadingNew';

const SilvaCourseDescription = () => {
    return (
        <div className="silva-course-description py-5 bg-white">
            <div className="course-overview">
                <HeadingNew title={"Course Description"} />
                <div className='mt-2'>
                    <HeadingNew small title={"How The Silva Method can change your life?"} />
                </div>
                <p className='robotofont mt-3 text-start'>
                    Have you ever envisioned a life that strikes the perfect balance between comfort and simplicity, where abundance meets mindfulness?
                    Silva Method says the answer lies within You. Whether we experience happiness, sadness, anger, or other emotions, it is rooted in our lack of understanding of our own thoughts. The key lies in taking control of our minds rather than allowing them to dictate our thoughts, feelings, and emotions.

                    The Silva Method is a self-improvement meditation technique or also called a self-religion developed by Jose Silva in the 1960s, after 24 years of immense studies. His purpose was to harness the power of the mind to acquire success and improve several aspects of life. This method consists of such mind-controlling techniques that reduce stress, increase relaxation, enhance intuition power and decision-making capabilities, and improve health and overall well-being.

                    Immerse yourself in The Silva Method techniques to cultivate a positive mindset and foster holistic well-being. Dedication and consistency in the process pave the way for life-altering transformation. By the journey's end, you'll discover a subtle yet profound shift in your perspective, where a positive mindset effortlessly becomes a natural extension of your being.


                    The complete Silva Method Course consists of Silva Life System, Silva Method Intuition System, Silva Method Manifesting System, Silva Mind Body Healing System, Silva Alpha Sound, and Silva Theta Sound.

                </p>
            </div>

            <section className="life-system  sectiondetails">
                <h2 style={{
                    color: "#3f15b3"
                }}>Silva Life System</h2>

                <div className="life-system-details">
                    <div className="life-system-description">
                        <p className='robotofont'>
                            We can see around us some people are seemingly fortunate and experience profound, transformative breakthroughs while some might still be struggling with financial difficulties, health issues, unfulfilled potential, and erratic ups and downs in their personal and professional life. By aligning thoughts, emotions, and actions with their envisioned future, individuals can unlock transformative potential, paving the way for fulfilling their deepest desires and becoming architects of their destiny.

                            The Silva Life System can help individuals achieve their goals and live a more fulfilling and successful life by tapping into their inner potential. Various techniques like meditation, visualization, and relaxation exercises are taught in this program to help individuals reduce stress levels and feel more calm and centered.

                            Silva Method life system is going to introduce you to something called dynamic or active Meditation. You’re going to learn how to meditate wherever you are, whether you’re on the go or even if your eyes are open. As you go through the program, you’re going to be asked to get clear about what you really want. And then you’re going to have the opportunity to evaluate what you want and your current situation so that you can measure your progress as you go through the program.
                        </p>

                    </div>

                    <div className="life-system-exercises">
                        <h3 className='mb-3'>Life System Exercises</h3>
                        <div className="exercises-grid">
                            {[
                                'Sleep Control',
                                'Clock Exercise',
                                'Awake Control',
                                'Dream Control',
                                'Headache Control',
                                'Three Fingers Technique',
                                'Hand Levitation',
                                'Mental Screen/Memory Pegs',
                                'Glass of Water Technique',
                                'Mirror of the Mind',
                                'Habit Control',
                                'Fantastic Voyage',
                                'Glove Anesthesia'
                            ].map((exercise, index) => (
                                <div key={index} className="exercise-item">{exercise}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className="intuition-system sectiondetails">
                <h2 style={{
                    color: "#3f15b3"
                }}>Silva Method Intuition System</h2>


                <div className="intuition-system-content">
                    <div className="intuition-description">
                        <p className='robotofont'>
                            Human intuition, an inherent and trainable skill, emerges from birth, evident in the profound bond between mothers and babies worldwide. Yet, external influences like cultural beliefs, societal norms, schooling, and upbringing often stifle this innate ability, restricting its full potential. Embracing and nurturing intuition can lead to greater insights and decision-making prowess.
                            One fundamental aspect of the Silva Method is the development of intuition. By tapping into the subconscious mind, anyone can access their intuitive abilities and gain insights and guidance beyond their logical thinking. The Silva Method provides techniques to enhance and trust one's intuition that will help you to make better decisions on all life’s aspects like marriage, career, investments, and more.
                        </p>

                    </div>

                    <div className="intuition-exercises">
                        <h3 className='mb-3' >Intuition System Exercises</h3>
                        <div className="exercises-grid">
                            {[
                                'Long Relaxation Exercise',
                                'Basics of Intuition',
                                'Droplet of Source Energy',
                                'Holovision',
                                'Projection in Metals',
                                'Projection to Plant Life/Pets',
                                'Projection in Human Anatomy',
                                'Eternal Garden Exercise',
                                'Orientation Exercises',
                                'Seeds of Purpose'
                            ].map((exercise, index) => (
                                <div key={index} className="exercise-item">{exercise}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className="live-sessions sectiondetails">
                <h2 style={{
                    color: "#3f15b3"
                }}>Weekly Live Interactive Sessions</h2>
                <p className='robotofont'>
                Join our weekly live interactive classes, guided by certified Silva Method instructors, every Saturday. These sessions are designed to enhance your Silva Method learning experience, helping you progress 2X faster while sharpening your skills.Have questions or face challenges while practicing? Bring them to the session! Our instructors will provide personalized guidance and powerful insights to support your journey.
Enjoy guided meditations and comprehensive learning from the comfort of your home, all while connecting with like-minded individuals who share your passion for growth. 

                </p>

           

                <div className="session-highlights">
                    <div className="detailshighlight">
                        <span>📅</span>
                        <p className='robotofont'>Every Saturday</p>
                    </div>
                    <div className="detailshighlight">
                        <span>🏠</span>
                        <p>From the Comfort of Home</p>
                    </div>
                    <div className="detailshighlight">
                        <span>👥</span>
                        <p>Connect with Like-Minded Individuals</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SilvaCourseDescription;