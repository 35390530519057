import React, { useState } from "react";
import { Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import "./newcombobanner.css";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

function NewComboBanner() {
  const { isMobile } = useContext(AuthContext);
  const [includeLiveClass, setIncludeLiveClass] = useState(false);


  const BasicCard = () => {
    return (
      <Card className="w-100 spanish_class_hero_bg4">
        <Card.Body>
          <div className="includelivecheck d-flex align-items-center justify-content-end mb-2 me-3">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Include Live Class"
              onChange={() => setIncludeLiveClass(!includeLiveClass)}
              checked={includeLiveClass}
            />
          </div>
          <Row className="px-4 align-items-center pb-3 gy-4">
            <Col
              sm={12}
              md={12}
              lg={12}
              xl={6}
              className={`pe-${isMobile ? "0" : "4"} text-white`}
            >
              <div>
                <Fade duration={700}>
                  <div className="mb-4">
                    <h2
                      style={{
                        color: "white",
                        textAlign: "start",
                        fontWeight: "700",
                        lineHeight: isMobile ? "1.8rem" : "3rem",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                      }}
                    >
                      <span style={{ color: "#FFD23F", display: "block" }}>
                        The Silva Method
                      </span>
                      <span style={{ fontSize: isMobile ? "1.3rem" : "2rem" }}>
                        Beginner Combo Course
                      </span>
                      {includeLiveClass ? (
                        <>
                          <br />
                          <span>
                            with{" "}
                            <span
                              style={{
                                padding: "5px",
                                borderRadius: "5px",
                                backgroundColor: "red",
                              }}
                            >
                              Live
                            </span>{" "}
                            Interactive Program
                          </span>
                        </>
                      ) : null}
                    </h2>
                  </div>
                </Fade>
                <div>
                  <p className="text-white m-0">
                    Eager to explore the Silva Method but don’t know where to
                    begin? The Silva Beginner Course can be your perfect
                    starting point.
                  </p>
                  <p className="text-white m-0">
                    The Silva Beginner Course provides exclusive access to 100%
                    original Jose Silva techniques, designed to harness your
                    mind's power and help you achieve your goals effectively.
                  </p>
                  <p className="text-white m-0">
                    This course is designed for beginners to introduce them to
                    personal development and transformation. Enroll now to start
                    your journey with the fundamental tools and techniques
                    needed for gaining clarity and achieve greater success.
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-warning m-0 mb-2 fw-bold">
                    The Silva Beginner Course offers:
                  </p>
                  <p className="text-white m-0">
                    <span>⚪</span> Silva life system
                  </p>
                  <p className="text-white m-0">
                    <span>⚪</span> Silva intuition system
                  </p>
                  <p className="text-white m-0">
                    <span>⚪</span> Alpha sound
                  </p>
                  <p className="text-white m-0">
                    <span>⚪</span> Theta sound
                  </p>
                </div>
               {includeLiveClass? <div className="mt-3">
                  <p className="text-warning m-0 mb-2 fw-bold">
                    Special Add-Ons: 3 Live Sessions with Experts
                  </p>
                  <p className="text-white m-0">
                    If you're seeking expert guidance on your Silva Method
                    journey, you can enroll in our live interactive sessions.
                    You'll receive three sessions each month with a certified
                    Silva Method instructor, providing you with valuable
                    knowledge and the chance to clear any doubts you may have.
                  </p>
                </div>:null}
              </div>
              <div className="my-3">
                <Link
                  to={`/store/course/begineer-combo-course`}
                >
                  <button
                    className="p-3 px-5 border border-0 rounded-3 fw-bold"
                    style={{ backgroundColor: "#ffd401" }}
                  >
                    Enroll Now
                  </button>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={12} lg={12} xl={6}>
              {includeLiveClass ? (
                <div>
                  <div>
                    <Card.Img
                      src={"/img/courseimgs/beginners-combo.webp"}
                      style={{ borderRadius: "15px", border: "1px solid #fff" }}
                    />
                  </div>
                  <div>
                    <div className="text-center">
                      <FaPlus size={35} color="#fff" />
                    </div>
                  </div>
                  <div>
                    <Card.Img
                      src={"/img/live_interaction_image_recoding.jpg"}
                      style={{ borderRadius: "15px", border: "1px solid #fff" }}
                    />
                  </div>
                </div>
              ) : (
                <Card.Img
                  src={"/img/courseimgs/beginner-combo-vertical.webp"}
                  style={{
                    borderRadius: "15px",
                    border: "1px solid #fff",
                    height: isMobile ? "auto" : "600px",
                    objectFit: "cover",
                  }}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const AdvancedCard = () => {
    return (
      <Card className="w-100 spanish_class_hero_bg4">
        <Card.Body>
          <div className="includelivecheck d-flex align-items-center justify-content-end mb-2 me-3">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Include Live Class"
              onChange={() => setIncludeLiveClass(!includeLiveClass)}
              checked={includeLiveClass}
            />
          </div>
          <Row className="px-4 align-items-center pb-3 gy-4">
            <Col
              sm={12}
              md={12}
              lg={12}
              xl={6}
              className={`pe-${isMobile ? "0" : "4"} text-white`}
            >
              <div>
              <Fade duration={700}>
                  <div className="mb-4">
                    <h2
                      style={{
                        color: "white",
                        textAlign: "start",
                        fontWeight: "700",
                        lineHeight: isMobile ? "1.8rem" : "3rem",
                        fontSize: isMobile ? "0.8rem" : "1rem",
                      }}
                    >
                      <span style={{ color: "#FFD23F", display: "block" }}>
                        The Silva Method
                      </span>
                      <span style={{ fontSize: isMobile ? "1.3rem" : "2rem" }}>
                        Advanced Combo Course
                      </span>
                      {includeLiveClass ? (
                        <>
                          <br />
                          <span>
                            with{" "}
                            <span
                              style={{
                                padding: "5px",
                                borderRadius: "5px",
                                backgroundColor: "red",
                              }}
                            >
                              Live
                            </span>{" "}
                            Interactive Program
                          </span>
                        </>
                      ) : null}
                    </h2>
                  </div>
                </Fade>
                <div>
                  <p className="text-white m-0">
                    Want to level up your Silva Method journey? If you've
                    mastered the basics and are eager for more, the Advanced
                    Combo Course is your next step. Dive deeper with exclusive
                    Jose Silva techniques, and explore the Silva Mind
                    Development Course to discover new dimensions of your
                    potential. Enroll now to achieve even greater
                    self-transformation!
                  </p>
                  <div className="mt-4">
                  <p className="text-warning m-0 mb-2 fw-bold">
                    The Silva Beginner Course offers:
                  </p>
                  <p className="text-white m-0">
                    <span>⚪</span> Silva Manifesting
                  </p>
                  <p className="text-white m-0">
                    <span>⚪</span> Silva mind body healing
                  </p>
                  <p className="text-white m-0">
                    <span>⚪</span> Alpha sound
                  </p>
                  <p className="text-white m-0">
                    <span>⚪</span> Theta sound
                  </p>
                </div>
               { includeLiveClass ?<div className="mt-3">
                  <p className="text-warning m-0 mb-2 fw-bold">
                    Special Add-Ons: 3 Live Sessions with Experts
                  </p>
                  <p className="text-white m-0">
                    If you're seeking expert guidance on your Silva Method
                    journey, you can enroll in our live interactive sessions.
                    You'll receive three sessions each month with a certified
                    Silva Method instructor, providing you with valuable
                    knowledge and the chance to clear any doubts you may have.
                  </p>
                </div>:null}
                </div>
              </div>
              <div className="my-3">
                <Link
                  to={`/store/course/advanced-combo-course`}
                >
                  <button
                    className="p-3 px-5 border border-0 rounded-3 fw-bold"
                    style={{ backgroundColor: "#ffd401" }}
                  >
                    Enroll Now
                  </button>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={12} lg={12} xl={6}>
              {includeLiveClass ? (
                <div>
                  <div>
                    <Card.Img
                      src={"/img/courseimgs/advanced-combo.webp"}
                      style={{ borderRadius: "15px", border: "1px solid #fff" }}
                    />
                  </div>
                  <div>
                    <div className="text-center">
                      <FaPlus size={35} color="#fff" />
                    </div>
                  </div>
                  <div>
                    <Card.Img
                      src={"/img/live_interaction_image_recoding.jpg"}
                      style={{ borderRadius: "15px", border: "1px solid #fff" }}
                    />
                  </div>
                </div>
              ) : (
                <Card.Img
                  src={"/img/courseimgs/Advanced combo-vertical.webp"}
                  style={{ borderRadius: "15px", border: "1px solid #fff",height:"600px",objectFit:"cover" }}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const tablist = [
    {
      id: 1,
      text: "Silva Method Beginner Course",
      content: "tab 1",
      card: <BasicCard />,
    },
    {
      id: 2,
      text: "Silva Method Advanced Course",
      content: "tab 2",
      card: <AdvancedCard />,
    },
  ];

  return (
    <div className="newcombobannercont pb-5">
      <div className="container">
        <Tab.Container id="left-tabs-example w-100" defaultActiveKey={1}>
          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="flex-row justify-content-center">
                <div
                  className={`d-flex ${
                    isMobile ? "flex-column" : ""
                  } p-1 rounded-full m-3 mb-5 mt-4 border-black`}
                >
                  {tablist.map((tab, i) => (
                    <Nav.Item key={i}>
                      <Nav.Link
                        eventKey={tab.id}
                        className="p-3 px-5 rounded-3 fw-bold"
                      >
                        {tab.text}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </div>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                {tablist.map((tab, i) => (
                  <Tab.Pane eventKey={tab.id} key={i}>
                    {tab?.card}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
}

export default NewComboBanner;
