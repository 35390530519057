import React from 'react'
import StressReductionHeroSection from './HeroSection'
import "./stressed.css"
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
function StressReductionTechniques() {
  return (
    <div>
        <HomeHeader />
        <StressReductionHeroSection />
        
        <CustomFooter />
    </div>
  )
}

export default StressReductionTechniques