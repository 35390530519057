import React from 'react'
import { Row } from 'react-bootstrap';
import StressImageCard from './StressImageCard';

function LongTermStressSolution() {

    const stressReliefTips = [
        {
            title: "Healthy Lifestyle Choices",
            description: "Sometimes, the best stress relief starts with the basics. Getting enough sleep, eating well, and drinking enough water create a strong foundation for emotional balance. When your body feels nourished and rested, your mind naturally becomes more resilient to stress. Simple self-care habits can have a ripple effect, helping you approach challenges with more energy and calm.",
            image: "https://picsum.photos/seed/healthy/800/400"
        },
        {
            title: "Stress Reduction Meditation",
            description: "Regular meditation is like pressing a reset button for the mind. It creates space to release tension, center ourselves, and simply breathe. As you meditate, your ability to handle stress strengthens, helping you face challenges with calm rather than overwhelm. Over time, meditation becomes a powerful anchor, transforming stress into something manageable instead of letting it build up. It’s a gentle, daily practice that slowly reshapes our lives for a better future.",
            image: "https://picsum.photos/seed/meditation/800/400"
        },
        {
            title: "Physical Exercise",
            description: "Moving your body isn’t just about fitness; it’s a natural way to reduce stress and lift your spirits. Exercise releases endorphin hormones, that help you feel calmer and more positive. Whether you’re into yoga, jogging, dancing, or even taking a short walk during a break, regular physical activity can make you feel much better equipped to handle stress over time.",
            image: "https://picsum.photos/seed/exercise/800/400"
        },
        {
            title: "Emotional Techniques",
            description: "Emotions can sometimes feel like waves, but you can learn some stress-coping skills to ride them. Practice Journaling, Writing down your thoughts and emotions can be a great release and can help you gain perspective. Take a moment each day to reflect on things you are grateful for. Shifting focus to positives can be a fantastic stress support method. When stress gets high, it’s easy to spiral into negative thinking. Train yourself to replace negative thoughts with positive affirmations.",
            image: "https://picsum.photos/seed/emotions/800/400"
        },
        {
            title: "Seeking Social Support",
            description: "Sometimes, just talking to someone can change everything. Reaching out to friends, family, or support groups gives us a sense of connection and comfort. Engaging in stress relief activities for groups can help you relax and connect with others. Consider organizing a game night, a group meditation session, or a team-building exercise. Shared laughter and fun activities can act as great stress busters.",
            image: "https://picsum.photos/seed/support/800/400"
        }
    ];

    return (
        <div className='container'>
            <h2 className='heading-large text-black text-start'>
                Which Is The Best Strategy for Avoiding Long-term Stress
            </h2>
            <h4 className='heading-medium text-black text-start'>
            5 Stress Reduction Techniques
            </h4>
            <p className='robotofont'>
                By choosing the right strategies and making small shifts in your habits, you can create a life where stress doesn’t control you. This isn’t about quick fixes; it’s about finding meaningful approaches that bring calm and balance, day after day.
            </p>
            <p className='robotofont'>
                Here are some coping mechanisms for stress that stand out as practical and beneficial for long-term stress relief:


            </p>

            <section class="resources-stressed">
                <div class="resources-stressed-grid">
                    {stressReliefTips.map((val)=>(
                        <StressImageCard 
                        description={val.description}
                        title={val.title}
                        image={val.image}
                        />
                    ))}

                </div></section>

        </div>
    )
}

export default LongTermStressSolution