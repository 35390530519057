// import React, { useState, useEffect, useContext } from 'react';
// import './ResponsiveSection.css';
// import moment from 'moment';
// import { AuthContext } from '../../../context/AllContext';

// const TimerSection = () => {
//     const {isMobile} = useContext(AuthContext);

//     const [timeLeft, setTimeLeft] = useState(0);
//     const [status, setStatus] = useState('');

//     useEffect(() => {
//         const updateStatus = () => {
//             const now = moment();
//             const dayOfWeek = now.day(); // 0 = Sunday, 6 = Saturday

//             // Hide timer on Saturday (6) and Sunday (0)
//             if (dayOfWeek === 0 || dayOfWeek === 6) {
//                 setStatus('inactive');
//                 return;
//             }

//             const startClass = moment().set({ hour: 19, minute: 0, second: 0 }); // Class start at 7:00 PM
//             const endClass = moment().set({ hour: 19, minute: 30, second: 0 }); // Class end at 7:30 PM
//             const showTimer = moment().set({ hour: 18, minute: 30, second: 0 }); // Show timer from 6:30 PM


//             if (now.isBetween(showTimer, startClass)) {
//                 // Before class starts, show timer and disable the button
//                 const duration = moment.duration(startClass.diff(now));
//                 setTimeLeft(Math.floor(duration.asSeconds()));
//                 setStatus('waiting');
//             } else if (now.isBetween(startClass, endClass)) {
//                 // During class, show "Class is ongoing"
//                 setStatus('ongoing');
//             } else {
//                 // After class, hide the component
//                 setStatus('inactive');
//             }
//         };

//         updateStatus();
//         const timer = setInterval(updateStatus, 1000);

//         return () => clearInterval(timer);
//     }, []);

//     const minutes = Math.floor(timeLeft / 60);
//     const seconds = timeLeft % 60;

//     if (status === 'inactive') return null;

//     return (
//         <div className="bg-immersive-container my-5">
//             <div className="bg-content-overlay">
//                 <div className="bg-textual-zone">
//                     <h3 className={isMobile?"robotofont py-1":"robotofont px-4 py-2"} style={{ color: 'white', fontSize: isMobile?"1.5rem":'4em',textTransform:"uppercase",background:"rgba(255,255,255,0.3)",width:isMobile?"100%":"75%",borderRadius:isMobile?"5px":"15px"}}>
//                         The wait is almost over!
//                     </h3>
//                     <p className="white-color robotofont text-center" style={{ fontSize: isMobile?"1.2rem":'1.5rem' ,width:isMobile?"100%":"75%", }}>
//                         Prepare to release stress and expand your mind beyond limits.
//                     </p>
                   
//                     <h3 className={isMobile?"robotofont text-center mt-2":"robotofont text-center mt-4"} style={{ color: 'white', fontSize: '2.5em',width:isMobile?"100%":"75%", }}>
//                          FREE MEDITATION SESSION
//                     </h3>

//                     {status === 'waiting' && (
//                         <>
//                            <div className='d-flex justify-content-center align-items-center flex-column'  style={{
//                             width:isMobile?"100%":"75%"
//                            }}>
                         
//                             <div
//                                 style={{
//                                     display: 'inline-block',
//                                     background: 'linear-gradient(173deg, rgba(102,68,254,1) 0%, rgba(155,128,255,1) 49%)',
//                                     color: 'white',
//                                     padding: '10px 15px',
                                    
//                                     borderRadius: '5px',
//                                     fontWeight: '600',
//                                     fontSize: '17px',
//                                     boxShadow:" 5px 8px 34px 11px rgba(102,68,254,0.46)"
//                                 }}
//                                 className="mt-3"
//                             >
//                                 BEGINS IN JUST
//                             </div>
//                             <div
//                                 style={{
//                                     display: 'flex',
//                                     justifyContent: 'center',
//                                     alignItems: 'center',
//                                     maxWidth: '500px',
//                                     borderRadius: '15px',
//                                     padding: '20px',
//                                   background:"rgba(255,255,255,0.3)",borderRadius:"15px",
                                 
//                                 }}
//                                 className="mt-4"
//                             >
//                                 <div
//                                     style={{
//                                         fontSize: '7rem',
//                                         color: 'white',
//                                         fontWeight: 'bold',
//                                         letterSpacing:"0.5rem"
//                                     }}
//                                     className='antonfont'
//                                 >
//                                     {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
//                                 </div>
//                             </div>
//                            </div>
                            
//                         </>
//                     )}

//                     {status === 'ongoing' && (
//                         <>
//                          <div className='d-flex justify-content-center align-items-center flex-column'  style={{
//                             width:isMobile?"100%":"75%"
//                            }}>

//                             <div
//                                 style={{
//                                     display: 'inline-block',
//                                     background: 'linear-gradient(173deg, rgba(102,68,254,1) 0%, rgba(155,128,255,1) 49%)',
//                                     color: 'white',
//                                     padding: '10px 15px',
                                    
//                                     borderRadius: '5px',
//                                     fontWeight: '600',
//                                     fontSize: '17px',
//                                     boxShadow:"5px 8px 34px 11px rgba(102,68,254,0.46)"
//                                 }}
//                                 className="mt-3"
//                             >
//                                 CLASS IS ONGOING
//                             </div>
                           
//                                 <button
//                                     style={{
//                                         display: 'flex',
//                                         alignItems: 'center',
//                                         justifyContent: 'center',
//                                         backgroundColor: '#0078FF', // Zoom-like blue color
//                                         color: 'white',
//                                         padding: '10px 20px',
//                                         border: 'none',
//                                         borderRadius: '10px',
//                                         fontSize: '1.5rem',
//                                         cursor: 'pointer',
//                                         fontWeight: 'bold',
//                                     }}
//                                     onClick={() => {
//                                         window.open("https://zoom.us/j/93916838996?pwd=dqeXgZzMazQDPRvl4vkTDjPnzH6XyL.1");
//                                     }}
//                                     className='mt-3'
//                                 >
//                                     <i
//                                         className="fas fa-video"
//                                         style={{
//                                             marginRight: '10px',
//                                             fontSize: '1.5rem',
//                                         }}
//                                     ></i>
//                                     Join Now
//                                 </button>
//                            </div>

//                         </>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default TimerSection;



import React, { useState, useEffect, useContext } from 'react';
import './ResponsiveSection.css';
import moment from 'moment';
import { AuthContext } from '../../../context/AllContext';

const TimerSection = () => {
    const {isMobile} = useContext(AuthContext);

    const [timeLeft, setTimeLeft] = useState(0);
    const [status, setStatus] = useState('');

    useEffect(() => {
        const updateStatus = () => {
            const now = moment();
            const classStart = moment().set({ hour: 19, minute: 0, second: 0 }); // 7:00 PM

            if (now.isBetween(classStart, classStart.clone().add(30, 'minutes'))) {
                setStatus('inactive');
                return;
            }

            const startClass = moment().set({ hour: 19, minute: 0, second: 0 }); // Class start at 7:00 PM
            const duration = moment.duration(startClass.diff(now));

            setTimeLeft(Math.floor(duration.asSeconds()));
            setStatus('waiting');
        };

        updateStatus();
        const timer = setInterval(updateStatus, 1000);

        return () => clearInterval(timer);
    }, []);

    const days = Math.abs(Math.floor(timeLeft / (24 * 3600)));
    const hours = Math.abs(Math.floor((timeLeft % (24 * 3600)) / 3600));
    const minutes = Math.abs(Math.floor((timeLeft % 3600) / 60));
    const seconds = Math.abs(timeLeft % 60);

    if (status === 'inactive') return null;

    return (
        <div className="bg-immersive-container my-5">
            <div className="bg-content-overlay">
                <div className="bg-textual-zone">
                    <h3 className={isMobile?"robotofont py-1":"robotofont px-4 py-2"} style={{ color: 'black',marginBottom:"20px", fontSize: isMobile?"1.5rem":'4em',textTransform:"uppercase",background:"#FFD23F",width:isMobile?"100%":"75%",borderRadius:isMobile?"5px":"15px"}}>
                        The wait is almost over!
                    </h3>
                    <p className="white-color robotofont text-center" style={{ fontSize: isMobile?"1.2rem":'1.5rem' ,width:isMobile?"100%":"75%", }}>
                        Prepare to release stress and expand your mind beyond limits.
                    </p>
                   
                    <h3 className={isMobile?"robotofont text-center mt-2":"robotofont text-center mt-4"} style={{ color: 'white', fontSize: '2.5em',width:isMobile?"100%":"75%", }}>
                         FREE MEDITATION SESSION
                    </h3>

                    <div className='d-flex justify-content-center align-items-center flex-column' style={{width:isMobile?"100%":"75%"}}>
                        <div
                            style={{
                                display: 'inline-block',
                                background: 'linear-gradient(90deg, rgb(53, 32, 230) 0%, rgb(217, 1, 252) 80%)',
                                color: 'white',
                                padding: '10px 15px',
                                borderRadius: '5px',
                                fontWeight: '600',
                                fontSize: '17px',
                                boxShadow: "5px 8px 34px 11px rgba(102,68,254,0.46)"
                            }}
                            className="mt-3"
                        >
                            BEGINS IN
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '15px',
                                padding: '20px',
                                borderRadius: "15px",
                                background:"#FFD23F"

                                
                            }}
                            className="mt-4"
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    fontSize: isMobile?"3rem":'4rem',
                                    letterSpacing:"0.6rem",
                                    color: 'black',
                                    fontWeight: '500',
                                }}
                                className='antonfont'
                            >
                                <div className='text-center' >
                                    <div>{Math.abs(days.toString().padStart(2, '0'))}:</div>
                                </div>
                                <div className='text-center'>
                                    <div>{hours.toString().padStart(2, '0')}:</div>
                                </div>
                                <div className='text-center'>
                                    <div>{minutes.toString().padStart(2, '0')}:</div>
                                </div>
                                <div className='text-center'>
                                    <div>{seconds.toString().padStart(2, '0')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimerSection;