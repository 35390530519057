import React, { useState, useEffect } from "react";
import moment from "moment";
import { FaWhatsapp } from "react-icons/fa";

const WeekdayButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const checkVisibility = () => {
            const now = moment();
            const dayOfWeek = now.day(); // 0 = Sunday, 6 = Saturday

            // Check if today is a weekday (Monday to Friday)
            if (dayOfWeek >= 1 && dayOfWeek <= 5) {
                const startTime = moment().set({ hour: 19, minute: 0, second: 0 }); // 6:30 PM
                const endTime = moment().set({ hour: 19, minute: 30, second: 0 }); // 7:00 PM
                // const startTime = moment().set({ hour: 11, minute: 0, second: 0 }); // 6:30 PM
                // const endTime = moment().set({ hour: 11, minute: 30, second: 0 }); // 7:00 PM

                // Check if the current time is between 6:30 PM and 7:00 PM
                if (now.isBetween(startTime, endTime)) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            } else {
                setIsVisible(false); // Hide on Saturday and Sunday
            }
        };

        checkVisibility();
        const interval = setInterval(checkVisibility, 1000); // Update every second

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    if (!isVisible) return null; // Hide the button if not visible

    return (
        <div style={{ marginTop: "20px" }}>
            <button
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#0078FF', // Zoom-like blue color
                    color: 'white',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '15px',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
                onClick={() => {
                    window.open("https://zoom.us/j/93916838996?pwd=dqeXgZzMazQDPRvl4vkTDjPnzH6XyL.1");
                }}
            >
                <i
                    className="fas fa-video"
                    style={{
                        marginRight: '10px',
                        fontSize: '1.5rem',
                    }}
                ></i>
                Join Now
            </button>
        </div>
    );
};

export default WeekdayButton;
