import React, { useContext } from 'react';
import BlackFridaySpecialOffers from './BlackFridaySpecialOffers';
import { AuthContext } from '../../../context/AllContext';


const BlackFridaySection = ({scrollTo}) => {
  const {isMobile} = useContext(AuthContext);

  return (
    <>

      <section className="bfs_wrapper">
        <div className="bfs_grid">
          <div className="bfs_col_left">
            <h2 className={isMobile?"bfs_exclusive text-white text-center":"bfs_exclusive text-white"}>Grab the Biggest Sale of the Year</h2>

            <div className="bfs_sale_container">
              <div className="bfs_sale_inner">
                <h1>
                  <span className="bfs_title_primary">BLACK</span>
                  <span className="bfs_title_accent ">FRIDAY</span>
                  <span className="bfs_title_script">Sale</span>
                </h1>
              
                 <img
                  src="/img/blackfriday/COUPON.webp"
                  alt="50% Off"
                  className="bfs_badge"
                />
              </div>
            </div>

            <p className="bfs_desc" style={{
              marginTop:isMobile?"30px":"0px"
            }}>
            Real Transformation Begins Here!
            </p>

          {
            isMobile ? (
              <div className='d-flex justify-content-center align-items-center'>
              <button className="bfs_cta" onClick={scrollTo}>Enroll Now !</button>
   
              </div>
            ):(
              <button className="bfs_cta" onClick={scrollTo}>Enroll Now !</button>
   
            )
          }

            
          </div>

          <div className="bfs_col_right">
            <img
              src="/img/blackfriday/giftbox.webp"
              // src='https://silvamethod.com/img/bg/bghero/flatimages/8.png'
              alt="Black Friday Gift"
              className="bfs_hero_img"
            />
          </div>
        </div>
      </section>

      
      
      <BlackFridaySpecialOffers scrollTo={scrollTo} />

      

     
    </>

  );
};

export default BlackFridaySection;