import React from "react";
import HeadingNew from "../../AboutUs/HeadingNew";
import ParaComp from "../../SilvaManifestationProgram/ParaComp";

function MindControlHero1() {
  return (
    <div style={{marginTop:"60px",marginBottom:"60px"}}>
      <div className="my-3">
        <HeadingNew title={"Silva Mind Control Courses-Learn from the"} small />
        <HeadingNew colored={"Comfort of your home"} />
      </div>
      <div className="container">
        <ParaComp 
            data={[
                "Silva mind control methods and techniques have now expanded their reach to the online sphere through Silva mind control courses. This initiative aims to spread awareness about Silva mind control techniques worldwide and empower individuals to harness their minds at deeper and more efficient levels. Enrolling in these courses grants access to comprehensive sup- port from the official Silva Method team.",
                "These courses fall under the umbrella of the Silva Method Complete Home study courses, meticulously designed to cater to the unique needs of each individual. The Silva Method book serves as an excellent resource, allowing individuals to explore the various levels of their minds through guided instruction. It offers the flexibility of learning powerful mind control techniques anytime and anywhere. Here's a glimpse of what the Silva mind control courses offer:"
            ]}
            fw={"400"}
            white={false}      
        />
      </div>
    </div>
  );
}

export default MindControlHero1;
