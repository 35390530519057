import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Offcanvas, Navbar, Container, Dropdown, Badge } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import { BsList, BsFillPlayCircleFill, BsFillPauseCircleFill } from 'react-icons/bs';
import './SilvaForAll.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CgClose } from 'react-icons/cg';
import axios from 'axios';

function SilvaForAll() {
  const navigate = useNavigate();
  const { id } = useParams();
  
  const [content, setContents] = useState([]);
  const [currentContent, setCurrentContent] = useState(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  
  const videoRef = useRef(null);
  const audioPlayerRef = useRef(null);

  // Memoized function to fetch content
  const fetchContents = useCallback(async () => {
    try {
      const res = await axios.get("https://silvacasesonline.com:5000/api/v1/admin/private/meditation-content");
      if (res && res.data) {
        setContents(res.data);
        
        // Find the current content based on the ID from the URL
        const foundContent = res.data.find((val) => val._id === id);
        setCurrentContent(foundContent || res.data[0]); // Default to first content if not found
      }
    } catch (err) {
      console.error("Error fetching content:", err);
      // Consider adding user-friendly error handling
    }
  }, [id]);

  // Fetch contents on component mount and when ID changes
  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  // Toggle offcanvas
  const handleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  // Handle lesson selection
  const handleLessonSelect = (selectedLesson) => {
    // Navigate to the selected lesson's route
    navigate(`/silva-for-all/${selectedLesson._id}`);
    
    // Close the offcanvas
    setShowOffcanvas(false);
    
    // Set the current content
    setCurrentContent(selectedLesson);
    
    // Reset playback rate
    setPlaybackRate(1);
    
    // Reset video/audio player if needed
    if (videoRef.current) {
      videoRef.current.load();
    }
    if (audioPlayerRef.current) {
      audioPlayerRef.current.audio.current.load();
    }
  };

  // Render nothing if no content is available
  if (!currentContent) {
    return <div>Loading...</div>;
  }

  return (
    <div className="sfa_container">
      {/* Header with burger menu */}
      <Navbar className="sfa_navbar">
        <Container>
          <BsList 
            size={35} 
            className="sfa_menu_icon" 
            onClick={handleOffcanvas}
          />
          <h1 className="sfa_title">{currentContent?.title || 'Content Player'}</h1>
        </Container>
      </Navbar>

      {/* Main content area */}
      <div className="sfa_content">
        {/* Video Player */}
        {currentContent?.type === 'video' && (
          <div className="sfa_video_container">
            <video
              ref={videoRef}
              key={currentContent._id} // Force re-render when content changes
              className="sfa_video"
              controls
              controlsList="nodownload"
              playsInline
              src={currentContent?.link}
              onLoadedMetadata={(e) => {
                e.target.playbackRate = playbackRate;
              }}
            />
            <div className="sfa_video_controls">
              <Dropdown>
                <Dropdown.Toggle variant="light" id="speed-dropdown">
                  Playback Speed
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((speed) => (
                    <Dropdown.Item 
                      key={speed} 
                      onClick={() => setPlaybackRate(speed)}
                    >
                      {speed === 1 ? 'Normal' : `${speed}x`}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}

        {/* Audio Player */}
        {currentContent?.type === 'audio' && (
          <div className="sfa_audio_container">
            <AudioPlayer
              ref={audioPlayerRef}
              key={currentContent._id} // Force re-render when content changes
              autoPlay={false}
              className="sfa_audio_player"
              src={currentContent?.link}
              onPlay={(e) => {
                e.target.playbackRate = playbackRate;
              }}
              customIcons={{
                play: <BsFillPlayCircleFill size={30} />,
                pause: <BsFillPauseCircleFill size={30} />
              }}
              header={currentContent.thumbnail && (
                <img 
                  src={currentContent.thumbnail} 
                  alt="Lesson Thumbnail"
                  style={{ width: "100%" }} 
                />
              )}
              customControlsSection={[
                <div className="sfa_audio_controls" key="speed-control">
                  <label>Playback Speed</label>
                  <select
                    value={playbackRate}
                    onChange={(e) => setPlaybackRate(parseFloat(e.target.value))}
                  >
                    {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((speed) => (
                      <option key={speed} value={speed}>
                        {speed === 1 ? 'Normal' : `${speed}x`}
                      </option>
                    ))}
                  </select>
                </div>,
                'MAIN_CONTROLS',
                'VOLUME_CONTROLS'
              ]}
            />
          </div>
        )}
      </div>

      {/* Offcanvas for lessons list */}
      <Offcanvas show={showOffcanvas} onHide={handleOffcanvas}>
        <Offcanvas.Header closeButton className='d-flex justify-content-between align-items-center'>
          <Offcanvas.Title className='header-common text-white'>Free Lessons</Offcanvas.Title>
          <CgClose color='white' size={25} onClick={() => setShowOffcanvas(false)} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="sfa_lessons_list">
            {content.map((lesson) => (
              <div 
                key={lesson._id}
                className="sfa_lesson_item" 
                style={{ cursor: "pointer" }} 
                onClick={() => handleLessonSelect(lesson)}
              >
                <img 
                  src={lesson?.thumbnail} 
                  alt={lesson?.title} 
                  className="sfa_lesson_thumb"
                />
                <div className="sfa_lesson_info">
                  <div 
                    className='d-flex gap-2 mb-3 robotofont flex-wrap' 
                    style={{ borderRadius: "15px" }}
                  >
                    {lesson.tags.map((tag) => (
                      <Badge key={tag.name} bg="success" className='py-2 px-3'>
                        {tag.name}
                      </Badge>
                    ))}
                  </div>
                  <h3>{lesson?.title}</h3>
                  <p>{lesson?.description}</p>
                  <span className={`sfa_lesson_type sfa_type_${lesson?.type}`}>
                    {lesson?.type}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default SilvaForAll;